import {
  HelpRequestEventData,
  IActivityRecord,
  IDeviceInfoRecord,
  IHealthRecord,
  ILocationRecord,
  ISettingsRecord,
  IUsageRecord,
  SeizureEventData,
} from 'models/dashboard.model';
import { PatientData } from 'types/patient';
import { formatIncidentDate, wasBefore } from './datetime-tools';
import { getHeartStatus } from './get-heart-status';
import { getLatestHeartAck } from './get-latest-heart-ack';
import { AlerterEventsWidgets } from 'types/alerters';
import dayjs from 'dayjs';

interface TransformPatientDataArgs {
  id: number;
  accountId: number;
  deviceId: number;
  name?: string;
  generatedAt?: string;
  createdAt?: string;
  activityRecord?: IActivityRecord;
  deviceInfoRecord?: IDeviceInfoRecord;
  healthRecord: IHealthRecord;
  settingsRecord: ISettingsRecord;
  usageRecord?: IUsageRecord;
  locationRecord?: ILocationRecord;
  helpRequest?: HelpRequestEventData | null;
  seizureDetected?: SeizureEventData | null;
  widgetsWithAlerts?: AlerterEventsWidgets[];
}

export const transformPatientData = ({
  id,
  accountId,
  deviceId,
  name,
  generatedAt,
  createdAt,
  deviceInfoRecord,
  healthRecord,
  settingsRecord,
  usageRecord,
  locationRecord,
  widgetsWithAlerts,
  helpRequest,
  seizureDetected,
}: TransformPatientDataArgs): PatientData => {
  const now = Date.now();
  const heartData = getHeartStatus(
    healthRecord.record_data,
    settingsRecord.record_data,
  );

  return {
    id,
    accountId,
    deviceId,
    name: name ?? settingsRecord.record_data.account_name,
    generatedAt: generatedAt ?? '',
    createdAt: createdAt ?? '',
    batteryLevel: {
      value: deviceInfoRecord?.record_data.battery_level ?? 0,
      isCharging: ['charging', 'full'].includes(
        deviceInfoRecord?.record_data.battery_state ?? '',
      ),
      lastUpdate: generatedAt,
      hasAlert: widgetsWithAlerts?.includes('battery'),
    },

    device: {
      isConfigOk: settingsRecord.record_data.configuration_ok,
      isActive:
        settingsRecord.record_data.any_device_active ??
        settingsRecord.record_data.device_active,
      isLocked: deviceInfoRecord?.record_data.device_locked,
      wornStatus:
        usageRecord?.record_data.most_recent_device_worn_status?.status,
      lastUpdate: generatedAt,
      hasAlert: widgetsWithAlerts?.includes('device'),
    },

    stepCount: {
      value:
        healthRecord.record_data.formatted_health_data?.daily_step_counts
          .value ?? undefined,
      lastUpdate:
        healthRecord.record_data.formatted_health_data?.daily_step_counts
          .end_date,
      hasAlert: widgetsWithAlerts?.includes('daily_step_count'),
      capable:
        healthRecord.record_data.formatted_health_data?.daily_step_counts
          .capable,
    },

    safeZone: {
      isInSafeZone: settingsRecord.record_data.lo_in_zone,
      lastUpdate: locationRecord?.record_data?.primary_location.timestamp,
      hasAlert: widgetsWithAlerts?.includes('safe_zone'),
    },

    fallAlert: {
      lastFall: formatIncidentDate(
        healthRecord.record_data.formatted_health_data?.fall_detection
          .start_date,
        now,
      ),
      hasUnreviewdFall: wasBefore(
        settingsRecord.record_data.last_health_acks.fall_detection,
        healthRecord.record_data.formatted_health_data?.fall_detection
          .start_date,
      ),
      lastUpdate:
        healthRecord.record_data.formatted_health_data?.fall_detection.end_date,
      hasAlert: widgetsWithAlerts?.includes('fall'),
      capable:
        healthRecord.record_data.formatted_health_data?.fall_detection.capable,
    },

    helpRequest: {
      requestedHelp:
        !!helpRequest &&
        wasBefore(
          dayjs().subtract(12, 'hours').toISOString(),
          helpRequest.end_date,
        ),
      lastUpdate: helpRequest?.end_date,
      hasAlert: widgetsWithAlerts?.includes('help_requested'),
    },

    heartRate: {
      ...heartData,
      value: healthRecord.record_data.formatted_health_data?.heart_rate.value,
      lastUpdate:
        healthRecord.record_data.formatted_health_data?.heart_rate.end_date,
      acknowledgedAt: getLatestHeartAck(settingsRecord.record_data),
      hasAlert: widgetsWithAlerts?.includes('heart_rate'),
      capable:
        healthRecord.record_data.formatted_health_data?.heart_rate.capable,
    },

    heartRhythm: {
      value:
        healthRecord.record_data.formatted_health_data?.afib_burden?.value ??
        null,
      isIrregularNow:
        !!healthRecord.record_data.formatted_health_data
          ?.irregular_heart_rate_event.value,
      lastUpdate:
        healthRecord.record_data.formatted_health_data
          ?.irregular_heart_rate_event.end_date,
      acknowledgedAt:
        settingsRecord.record_data.last_health_acks.irregular_heart_rate_event,
      hasAlert: widgetsWithAlerts?.includes('heart_rhythm'),
      capable:
        healthRecord.record_data.formatted_health_data
          ?.irregular_heart_rate_event.capable,
    },

    bloodOxygen: {
      value: healthRecord.record_data.formatted_health_data?.blood_oxygen.value,
      lastUpdate:
        healthRecord.record_data.formatted_health_data?.blood_oxygen?.end_date,
      hasAlert: widgetsWithAlerts?.includes('blood_oxygen'),
      capable:
        healthRecord.record_data.formatted_health_data?.blood_oxygen.capable,
    },

    dailySleep: {
      value: healthRecord.record_data.formatted_health_data?.daily_sleep.value,
      lastUpdate:
        healthRecord.record_data.formatted_health_data?.daily_sleep?.end_date,
      hasAlert: widgetsWithAlerts?.includes('daily_sleep_time'),
      capable:
        healthRecord.record_data.formatted_health_data?.daily_sleep.capable,
    },

    wristTemperature: {
      value:
        healthRecord.record_data.formatted_health_data?.wrist_temperature
          ?.value,
      lastUpdate:
        healthRecord.record_data.formatted_health_data?.wrist_temperature
          ?.end_date,
      hasAlert: widgetsWithAlerts?.includes('wrist_temperature'),
      capable:
        healthRecord.record_data.formatted_health_data?.wrist_temperature
          ?.capable,
    },

    sleepApnea: {
      value:
        !!healthRecord.record_data.formatted_health_data?.sleep_apnea_event
          ?.end_date,
      lastUpdate:
        healthRecord.record_data.formatted_health_data?.sleep_apnea_event
          ?.end_date,
      hasAlert: widgetsWithAlerts?.includes('sleep_apnea'),
      capable:
        healthRecord.record_data.formatted_health_data?.sleep_apnea_event
          ?.capable,
    },

    breathingDisturbance: {
      value:
        healthRecord.record_data.formatted_health_data?.breathing_disturbances
          ?.value,
      lastUpdate:
        healthRecord.record_data.formatted_health_data?.breathing_disturbances
          ?.end_date,
      hasAlert: widgetsWithAlerts?.includes('breathing_disturbances'),
      capable:
        healthRecord.record_data.formatted_health_data?.breathing_disturbances
          ?.capable,
    },

    afibBurden: {
      value: healthRecord.record_data.formatted_health_data?.afib_burden?.value,
      lastUpdate:
        healthRecord.record_data.formatted_health_data?.afib_burden?.end_date,
      hasAlert: widgetsWithAlerts?.includes('heart_rhythm'),
      capable:
        healthRecord.record_data.formatted_health_data?.afib_burden?.capable,
    },

    energyBurn: {
      value:
        healthRecord.record_data.formatted_health_data?.daily_total_energy_burn
          ?.value,
      lastUpdate:
        healthRecord.record_data.formatted_health_data?.daily_total_energy_burn
          ?.end_date,
      hasAlert: widgetsWithAlerts?.includes('daily_calorie_burn'),
      capable:
        healthRecord.record_data.formatted_health_data?.daily_total_energy_burn
          ?.capable,
    },

    vO2: {
      value:
        healthRecord.record_data.formatted_health_data?.cardio_fitness?.value,
      lastUpdate:
        healthRecord.record_data.formatted_health_data?.cardio_fitness
          ?.end_date,
      capable:
        healthRecord.record_data.formatted_health_data?.cardio_fitness?.capable,
    },

    seizureDetected: {
      detected:
        !!seizureDetected &&
        wasBefore(
          dayjs().subtract(12, 'hours').toISOString(),
          seizureDetected?.end_date,
        ),
      lastUpdate: seizureDetected?.end_date,
      hasAlert: widgetsWithAlerts?.includes('motion_disorder'),
    },

    location: locationRecord?.record_data
      ? {
          lat: locationRecord.record_data.primary_location.lat,
          lng: locationRecord.record_data.primary_location.lng,
          accuracy: locationRecord.record_data.primary_location.accuracy,
          lastUpdate: locationRecord.record_data.primary_location.timestamp,
        }
      : null,
  };
};
