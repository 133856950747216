import {
  AppBar,
  AppBarProps,
  Avatar,
  Box,
  Fade,
  IconButton as MuiIconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { IconButton } from 'components/IconButton';
import { useAppbar } from '../hooks/use-appbar';
import TopBarLogo from './TopBarLogo';
import AvatarMenu from './AvatarMenu';
import { useState } from 'react';
import { useAppSelector } from 'store';
import { useAdminStatusQuery } from 'store/api/auth.api';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from 'nav';
import { getSideBarState, toggleSidebar } from 'services/sidebar';
import * as icons from 'assets/iconify';
import { Icon } from '@iconify/react';

const TopBar = (props: AppBarProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const hideLogo = getSideBarState();

  const accountId = useAppSelector((state) => state.user.accountId);
  const profileId = useAppSelector((state) => state.dashboard.profileId);
  const { data: isAdmin = false } = useAdminStatusQuery(
    { userId: accountId as number },
    {
      skip: !accountId,
    },
  );

  const [avatarMenuAnchorEl, setAvatarMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const isAvatarMenuOpen = !!avatarMenuAnchorEl;

  const handleAvatarMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAvatarMenuAnchorEl(event.currentTarget);
  };
  const handleAvatarMenuClose = () => {
    setAvatarMenuAnchorEl(null);
  };

  const { avatarText, viewTitle, tooltipContent } = useAppbar();

  const shouldShowAdminBtn =
    isAdmin &&
    profileId &&
    location.pathname === routes.private.dashboard.href(profileId);

  return (
    <AppBar
      position="sticky"
      elevation={2}
      sx={{
        alignItems: 'center',
        flexDirection: 'row',
        display: 'grid',
        gridTemplateColumns: '2fr auto 2fr',
        bgcolor: theme.palette.white.main,
        color: theme.palette.primary.main,
        height: theme.spacing(8),
        p: 1,
      }}
      {...props}
    >
      <Box flexGrow={1} display="flex" alignItems="center">
        <IconButton icon="material-symbols:menu" onClick={toggleSidebar} />
        <Box display="flex" alignItems="center" gap={1}>
          <Typography
            sx={{ pl: 1, display: { xxs: 'none', newMd: 'block' } }}
            whiteSpace="nowrap"
            variant="h6"
          >
            {viewTitle}
          </Typography>
          {tooltipContent && (
            <Tooltip
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -2],
                      },
                    },
                  ],
                },
              }}
              title={tooltipContent}
              placement="bottom-start"
            >
              <Icon
                color={theme.palette.blue.light}
                width={24}
                height={24}
                icon={icons.info.icons.normal}
              />
            </Tooltip>
          )}
        </Box>
      </Box>
      <Fade in={!hideLogo}>
        <Box px={1}>
          <TopBarLogo hideOnSmallScreens />
        </Box>
      </Fade>
      <Box flexGrow={1} display="flex" justifyContent="end">
        {!!shouldShowAdminBtn && (
          <Tooltip title="View in admin panel">
            <span>
              <IconButton
                onClick={() =>
                  navigate(routes.admin.lookupResults.href(profileId))
                }
                icon="material-symbols:admin-panel-settings-outline"
              />
            </span>
          </Tooltip>
        )}
        <MuiIconButton
          sx={{ ml: 1, mr: { xxs: 0, xs: 0.5, sm: 1, newMd: 2 }, p: 0 }}
          onClick={handleAvatarMenuOpen}
        >
          <Avatar
            sx={{
              width: '48px',
              height: '48px',
              bgcolor: theme.palette.primary.main,
            }}
          >
            {avatarText}
          </Avatar>
        </MuiIconButton>
      </Box>
      <AvatarMenu
        anchorEl={avatarMenuAnchorEl}
        open={isAvatarMenuOpen}
        onClose={handleAvatarMenuClose}
      />
    </AppBar>
  );
};

export default TopBar;
