import { alpha, BoxProps, Typography } from '@mui/material';
import { Box } from '@mui/material';
import { Table } from 'components/Table';
import { memo, ReactNode } from 'react';
import { useRecordsTable } from '../hooks';

const NoDataText = ({ children }: { children: ReactNode }) => (
  <Box height={1} display="flex" justifyContent="center" alignItems="center">
    <Typography
      fontSize={16}
      sx={{ color: ({ palette }) => alpha(palette.primary.main, 0.6) }}
    >
      {children}
    </Typography>
  </Box>
);

const DataTable = () => {
  const {
    rows,
    columns,
    limit,
    page,
    isLoading,
    totalNumber,
    handlePaginationModelChange,
    requestFields,
  } = useRecordsTable();

  return (
    <Table
      loading={isLoading}
      columns={columns}
      rowCount={totalNumber}
      paginationMode="server"
      paginationModel={{
        pageSize: limit,
        page,
      }}
      onPaginationModelChange={handlePaginationModelChange}
      rows={rows ?? []}
      slots={{
        noRowsOverlay: NoDataText as React.ComponentType<BoxProps>,
      }}
      slotProps={{
        noRowsOverlay: {
          children:
            requestFields.length === 0
              ? 'Please select at least one option from the filters above.'
              : 'No results',
        },
      }}
      rowHeight={52}
    />
  );
};

export default memo(DataTable);
