import React from 'react';
import { gridClasses } from '@mui/x-data-grid-pro';
import { usePatientsOverviewTable } from 'pages/PatientsOverview/hooks/use-patients-overview-table';
import { useColumns } from 'pages/PatientsOverview/hooks/use-columns';
import { Table } from 'components/Table';
import { alpha, styled } from '@mui/material';
import { useTableScroll } from 'hooks/use-table-scroll';
import { useAppSelector } from 'store';

const StyledTable = styled(Table)`
  .${gridClasses.row} {
    background-color: ${({ theme }) => theme.palette.background.paper};
  }

  .${gridClasses['columnHeader--withRightBorder']} {
    border-right: 0px;
  }

  .first-cell {
    border-left: 4px solid transparent;
  }

  .${gridClasses.row} {
    cursor: pointer;
    &.selected {
      .${gridClasses.cell} {
        background-color: ${({ theme }) =>
          alpha(theme.palette.secondary.main, 0.16)};
        &.first-cell {
          z-index: 1;
          background-color: #e1eeeb;
          border-left: 4px solid ${({ theme }) => theme.palette.secondary.main};
        }
      }
    }
  }
`;

const PopOvTable = () => {
  const activeId = useAppSelector((state) => state.dashboard.profileId);
  const { requestFields, visibleColumns, columns } = useColumns();

  const {
    rows,
    totalNumber,
    isLoading,
    rowsPerPage,
    page,
    handlePaginationModelChange,
    handleRowClick,
  } = usePatientsOverviewTable({ requestFields, visibleColumns });

  const { apiRef } = useTableScroll();

  return (
    <StyledTable
      apiRef={apiRef}
      columns={columns}
      rows={rows}
      rowHeight={52}
      loading={isLoading}
      onRowClick={handleRowClick}
      getRowClassName={({ id }) => (id === activeId ? 'selected' : '')}
      getCellClassName={({ field }) => (field === 'name' ? 'first-cell' : '')}
      initialState={{
        pinnedColumns: {
          left: ['name'],
        },
        pagination: {
          paginationModel: { pageSize: rowsPerPage, page },
        },
      }}
      rowCount={totalNumber}
      paginationMode="server"
      onPaginationModelChange={handlePaginationModelChange}
    />
  );
};

export default React.memo(PopOvTable);
