import {
  Box,
  Divider,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
  useTheme,
} from '@mui/material';
import { Icon } from '@iconify/react';
import { useLogout } from 'hooks/use-logout';
import { useNavigate } from 'react-router-dom';
import { routes } from 'nav';
import * as icons from 'assets/iconify';

const AvatarMenu = (props: MenuProps & { onClose: () => void }) => {
  const { logout } = useLogout();
  const theme = useTheme();
  const navigate = useNavigate();

  const handleNavigateToSettings = () => {
    props.onClose();
    navigate(routes.private.settings.href);
  };

  return (
    <Menu
      elevation={6}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 90,
      }}
      sx={{ mt: 3 }}
      {...props}
    >
      <MenuItem
        sx={{ py: 0, minHeight: 'auto' }}
        onClick={handleNavigateToSettings}
      >
        <Box
          mr={1}
          minWidth={theme.spacing(2.5)}
          display="flex"
          alignItems="center"
        >
          <Icon
            icon={icons.avatarMenu.icons.settings}
            width={20}
            color={theme.palette.primary.main}
          />
        </Box>
        <ListItemText
          slotProps={{
            primary: {
              lineHeight: 2,
              color: theme.palette.primary.main,
            },
          }}
        >
          Settings
        </ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem sx={{ py: 0, minHeight: 'auto' }} onClick={logout}>
        <Box
          mr={1}
          minWidth={theme.spacing(2.5)}
          display="flex"
          alignItems="center"
        >
          <Icon
            icon={icons.avatarMenu.icons.logout}
            width={20}
            color={theme.palette.primary.main}
          />
        </Box>
        <ListItemText
          slotProps={{
            primary: {
              lineHeight: 2,
              color: theme.palette.primary.main,
            },
          }}
        >
          Log out
        </ListItemText>
      </MenuItem>
    </Menu>
  );
};

export default AvatarMenu;
