/* eslint-disable @typescript-eslint/no-empty-function */
import AddSafeZoneModal from './AddSafeZoneModal';
import { Box, Button, Grid2, Skeleton } from '@mui/material';
import ZoneCard from './ZoneCard';
import { SafeZone, SafeZoneParams } from 'types/safe-zones';
import { createContext, useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { caregiverRole } from 'services/caregiver-role';

interface ZonesListProps {
  zones: SafeZone[];
  isLoading?: boolean;
  onSave?: (safeZone: SafeZoneParams) => void;
  onDelete?: (id: number) => void;
  buttonToLeft?: boolean;
}

export const MapModalContext = createContext<{
  isOpen: boolean;
  setHasUnsavedChanges: (hasChanges: boolean) => void;
  defaultValues: SafeZone | null;
  handleClose: () => void;
}>({
  isOpen: false,
  defaultValues: null,
  setHasUnsavedChanges: () => {},
  handleClose: () => {},
});

const ZonesList = ({
  zones,
  isLoading,
  onSave,
  onDelete,
  buttonToLeft,
}: ZonesListProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedZone, setSelectedZone] = useState<SafeZone | null>(null);

  const handleEdit = (zone: SafeZone) => {
    setSelectedZone(zone);
    setIsOpen(true);
  };

  useEffect(() => {
    if (!isOpen && selectedZone) setSelectedZone(null);
  }, [isOpen]);

  return (
    <div>
      <Grid2
        container
        spacing={{ xxs: 2, sm: 3 }}
        py={1}
        px={{ xxs: 0, newLg: 1 }}
      >
        {isLoading ? (
          <>
            <Grid2 size={{ xxs: 12, xl: 6, '3xl': 4 }}>
              <Skeleton variant="rounded" height={332} sx={{ mb: 1 }} />
            </Grid2>
            <Grid2 size={{ xxs: 12, xl: 6, '3xl': 4 }}>
              <Skeleton variant="rounded" height={332} sx={{ mb: 1 }} />
            </Grid2>
          </>
        ) : (
          zones.map((zone) => (
            <Grid2 key={zone.id} size={{ xxs: 12, xl: 6, '3xl': 4 }}>
              <ZoneCard
                onDelete={onDelete}
                zone={zone}
                onEdit={() => handleEdit(zone)}
              />
            </Grid2>
          ))
        )}
      </Grid2>
      <Box
        sx={{
          display: 'flex',
          justifyContent: buttonToLeft ? undefined : 'flex-end',
          px: {
            newLg: 1,
          },
        }}
      >
        {!(caregiverRole.value === 'viewer') && (
          <Button
            sx={{
              mt: {
                xxs: 2,
                sm: 3,
              },
              flexGrow: {
                xxs: 1,
                sm: 'unset',
              },
            }}
            onClick={() => setIsOpen(true)}
            startIcon={<Icon icon="material-symbols:add-box-outline" />}
          >
            Add Zone
          </Button>
        )}
      </Box>
      {isOpen && (
        <AddSafeZoneModal
          onSave={onSave}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          selectedZone={selectedZone}
        />
      )}
    </div>
  );
};

export default ZonesList;
