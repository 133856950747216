import { Box, Grid2, Typography } from '@mui/material';
import VInput from 'components/VInput';
import { useAppSelector } from 'store';
import { useNameInputs } from '../hooks/use-name-inputs';
import { useRef } from 'react';
import { PhoneNumber } from './PhoneNumber';

interface Props {
  defaultFirstName?: string;
  defaultLastName?: string;
  defaultPhoneNumber?: string;
  phoneNumberCredentialId?: number;
}

const Account = ({
  defaultFirstName = '',
  defaultLastName = '',
  defaultPhoneNumber,
  phoneNumberCredentialId,
}: Props) => {
  const email = useAppSelector((state) => state.user.email);
  const firstNameInputRef = useRef<HTMLInputElement>(null);
  const lastNameInputRef = useRef<HTMLInputElement>(null);
  const { changeFirstName, changeLastName, firstNameError } = useNameInputs(
    defaultFirstName,
    defaultLastName,
    firstNameInputRef,
    lastNameInputRef,
  );

  return (
    <div>
      <Typography variant="body1" fontWeight={600} color="primary">
        General
      </Typography>
      <Box px={{ xxs: 1, newMd: 2 }} pb={2} pt={2.5}>
        <Grid2 container rowSpacing={{ xxs: 2, sm: 3 }} columnSpacing={2}>
          <Grid2 size={{ xxs: 12, sm: 6 }}>
            <VInput
              disableWhitespace
              label="First name"
              size="large"
              placeholder="Enter your first name"
              onChange={changeFirstName}
              defaultValue={defaultFirstName}
              fullWidth
              inputRef={firstNameInputRef}
              helperText={firstNameError}
              error={!!firstNameError}
            />
          </Grid2>
          <Grid2 size={{ xxs: 12, sm: 6 }}>
            <VInput
              disableWhitespace
              label="Last name"
              placeholder="Enter your last name"
              size="large"
              onChange={changeLastName}
              defaultValue={defaultLastName}
              fullWidth
              inputRef={lastNameInputRef}
            />
          </Grid2>
          <Grid2 size={12}>
            <VInput
              disableWhitespace
              readOnly
              label="Email"
              size="large"
              defaultValue={email}
            />
          </Grid2>
          <PhoneNumber
            credentialId={phoneNumberCredentialId}
            phoneNumber={defaultPhoneNumber}
          />
        </Grid2>
      </Box>
    </div>
  );
};

export default Account;
