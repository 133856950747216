import { Grid2, Skeleton } from '@mui/material';
import { Widget, WidgetProps } from 'components/Widget';
import { useParams } from 'react-router-dom';
import { getSideBarState } from 'services/sidebar';
import { useGetAlerterEventsByWidgetsQuery } from 'store/api/alerters.api';
import { AlerterEventsWidgets } from 'types/alerters';
interface Props {
  widgets: WidgetProps[];
  isLoading?: boolean;
  isFetching?: boolean;
}

const EXPECTED_NUMBER_OF_WIDGETS = 6;

const PlaceholderGrid = () => (
  <>
    {[...Array(EXPECTED_NUMBER_OF_WIDGETS)].map((_, index) => (
      <Grid2 key={index} size={1}>
        <Skeleton
          variant="rectangular"
          sx={({ spacing }) => ({
            borderRadius: spacing(1),
            height: spacing(23),
            width: 1,
          })}
        />
      </Grid2>
    ))}
  </>
);

const WidgetsGrid = ({ widgets, isLoading, isFetching }: Props) => {
  const isOpen = getSideBarState();
  const { profileId } = useParams();

  const { data: alertEvents } = useGetAlerterEventsByWidgetsQuery({
    profileId: Number(profileId),
  });

  const widgetAcknowledgeData = Object.keys(alertEvents?.results ?? {}).reduce(
    (acc, current) => {
      const alertedEvent = alertEvents?.results[
        current as AlerterEventsWidgets
      ].find((event) => event.requiresAcknowledgement && !event.acknowledgedAt);

      return {
        ...acc,
        [current]: {
          acknowledgedAt: alertedEvent?.acknowledgedAt,
          alertedAt: alertedEvent?.triggeredAt,
        },
      };
    },
    {} as Record<
      AlerterEventsWidgets,
      { acknowledgedAt?: string; alertedAt?: string }
    >,
  );

  const columns = isOpen
    ? { xxs: 1, newMd: 2, newLg: 3, newXl: 2, new2Xl: 3, new3Xl: 4, new4Xl: 5 }
    : { xxs: 1, newMd: 2, newLg: 3, newXl: 3, new2Xl: 4, new3Xl: 5, new4Xl: 5 };

  return (
    <Grid2 container columns={columns} spacing={2} mt={2}>
      {isFetching ? (
        <PlaceholderGrid />
      ) : (
        widgets.map((data, index) => (
          <Grid2 key={index} size={1}>
            <Widget
              fullWidth
              isLoading={isLoading}
              {...data}
              {...(data.widgetType
                ? widgetAcknowledgeData[data.widgetType]
                : {})}
            />
          </Grid2>
        ))
      )}
    </Grid2>
  );
};

export default WidgetsGrid;
