import { Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { DataCell } from 'components/DataCell';
import { getBloodOxygenStatus } from './get-blood-oxygen-status';
import { getDeviceConfigStatus } from './get-device-config-status';
import { getFallDetectionStatus } from './get-fall-detection-status';
import { getHeartRateStatus } from './get-heart-rate-status';
import { getZoneStatus } from './get-zone-status';
import { getBatteryLevelStatus } from './get-battery-level-status';
import { PatientData } from 'types/patient';
import { FiltersAppleWatchKeys } from 'store/reducers/filters/types';
import { getHeartRhythmStatus } from './get-heart-rhythm-status';
import { getHelpRequestStatus } from './get-help-request-status';
import { getSeizureDetectedData } from './get-seizure-detected-data';

const commonColumnProperties: Partial<GridColDef> = {
  editable: false,
  filterable: false,
  sortable: false,
  hideable: false,
  flex: 1,
  disableColumnMenu: true,
};

export type PopulationOverviewColumn = FiltersAppleWatchKeys;

export const columns: GridColDef<PatientData>[] = [
  {
    ...commonColumnProperties,
    field: 'name',
    headerName: 'Full Name',
    minWidth: 160,
    headerClassName: 'first-cell',
    renderCell: ({ row }) => (
      <Typography variant="body1" color="primary" className="patient-name">
        {row.name}
      </Typography>
    ),
  },
  {
    ...commonColumnProperties,
    field: 'device',
    headerName: 'Device',
    minWidth: 180,
    renderCell: ({ row }) => (
      <DataCell
        {...getDeviceConfigStatus(
          row.device?.isConfigOk ?? false,
          row.device?.wornStatus,
          row.device?.isActive,
          row.device?.isLocked,
        )}
      />
    ),
  },
  {
    ...commonColumnProperties,
    field: 'battery',
    headerName: 'Battery Level',
    minWidth: 160,
    renderCell: ({ row }) => {
      const { value, label, ...rest } = getBatteryLevelStatus(
        row.batteryLevel?.value,
        row.batteryLevel?.isCharging,
      );

      return <DataCell label={`${value} ${label}`} {...rest} />;
    },
  },
  {
    ...commonColumnProperties,
    field: 'safeZone',
    headerName: 'Safe Zone',
    minWidth: 120,
    renderCell: ({ row }) => (
      <DataCell {...getZoneStatus(row.safeZone?.isInSafeZone)} />
    ),
  },
  {
    ...commonColumnProperties,
    field: 'fallDetection',
    headerName: 'Falls',
    minWidth: 90,
    renderCell: ({ row }) => (
      <DataCell {...getFallDetectionStatus(row.fallAlert?.hasUnreviewdFall)} />
    ),
  },
  {
    ...commonColumnProperties,
    field: 'heartRate',
    headerName: 'Heart Rate',
    minWidth: 160,
    renderCell: ({ row }) => {
      return <DataCell {...getHeartRateStatus(row.heartRate?.status)} />;
    },
  },
  {
    ...commonColumnProperties,
    field: 'heartRhythm',
    headerName: 'Heart Rhythm',
    minWidth: 160,
    renderCell: ({ row }) => {
      return (
        <DataCell
          {...getHeartRhythmStatus(
            row.heartRhythm?.value,
            row.heartRhythm?.isIrregularNow,
          )}
        />
      );
    },
  },
  {
    ...commonColumnProperties,
    field: 'bloodOxygen',
    headerName: 'Blood Oxygen',
    minWidth: 140,
    renderCell: ({ row }) => {
      return <DataCell {...getBloodOxygenStatus(row.bloodOxygen?.value)} />;
    },
  },
  {
    ...commonColumnProperties,
    field: 'helpRequested',
    headerName: 'Help Request',
    minWidth: 140,
    renderCell: ({ row }) => {
      return (
        <DataCell {...getHelpRequestStatus(row.helpRequest?.requestedHelp)} />
      );
    },
  },
  {
    ...commonColumnProperties,
    field: 'seizureDetected',
    headerName: 'Seizure Detected',
    minWidth: 140,
    renderCell: ({ row }) => (
      <DataCell {...getSeizureDetectedData(row.seizureDetected?.detected)} />
    ),
  },
];
