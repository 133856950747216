/* eslint-disable @typescript-eslint/no-empty-function */
import { Backdrop, Box, Button, TextField, debounce } from '@mui/material';
import { HelperText } from 'pages/AlerterDetails/components/Settings/styled';
import EditMap from './EditMap';
import RadiusSlider from './RadiusSlider';
import SearchInput from './SearchInput';
import {
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { RegionsType, SafeZone, SafeZoneParams } from 'types/safe-zones';
import { useConfirmation } from 'hooks/use-confirmation';
import { BasicModal } from 'components/BasicModal';

export const DEFAULT_RADIUS = 65;

interface NewSafeZoneContextType {
  type: RegionsType;
  setType: (type: RegionsType) => void;
  radius: number;
  setRadius?: (radius: number) => void;
  setSave: (f: (title: string) => void) => void;
  setDiscard: (f: () => void) => void;
  onClose: () => void;
  setIsLoading: (value: boolean) => void;
  setIsSaveActive: (isActive: boolean) => void;
  onSave?: (safeZone: SafeZoneParams) => void;
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void;
  title: string;
}

export const NewSafeZoneContext = createContext<NewSafeZoneContextType>({
  type: RegionsType.CIRCLE_TYPE,
  setType: () => {},
  setSave: () => {},
  onClose: () => {},
  setIsLoading: () => {},
  setDiscard: () => {},
  setIsSaveActive: () => {},
  setHasUnsavedChanges: () => {},
  radius: DEFAULT_RADIUS,
  title: '',
});

const AddSafeZoneModal = ({
  isOpen,
  setIsOpen,
  selectedZone = null,
  onSave,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  selectedZone?: SafeZone | null;
  onSave?: (safeZone: SafeZoneParams) => void;
}) => {
  const [titleError, setTitleError] = useState<string | null>(null);
  const [type, setType] = useState<RegionsType>(
    selectedZone?.shapeType ?? RegionsType.CIRCLE_TYPE,
  );
  const [radius, setRadius] = useState<number>(DEFAULT_RADIUS);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const [isSaveActive, setIsSaveActive] = useState<boolean>(false);
  const [save, setSave] = useState<(title: string) => void>(() => () => {});
  const [discard, setDiscard] = useState<() => void | null>();
  const [isLoading, setIsLoading] = useState(false);
  const [sharedTitle, setSharedTitle] = useState('');
  const titleRef = useRef<string>('');
  const titleInputRef = useRef<HTMLInputElement>(null);
  const confirm = useConfirmation();

  const close = (
    // eslint-disable-next-line @typescript-eslint/ban-types
    e: {},
    reason: 'backdropClick' | 'escapeKeyDown' | 'custom',
  ) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    setIsOpen(false);
    setIsSaveActive(false);
    setHasUnsavedChanges(false);
    setType(RegionsType.CIRCLE_TYPE);
  };

  const onClose = () => {
    close({}, 'custom');
  };

  const handleRadiusChange = (value: number | number[]) => {
    setRadius(value as number);
    if (value !== selectedZone?.radius && !hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
    setIsSaveActive(
      selectedZone ? value !== selectedZone.radius || hasUnsavedChanges : true,
    );
  };

  const handleTitleChange = debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.target.value.length !== 0 && setTitleError(null);
      setHasUnsavedChanges(true);
      titleRef.current = event.target.value;
      setSharedTitle(event.target.value);
    },
    500,
  );

  const handleSetSave = (f: (title: string) => void) => {
    setSave(() => f);
  };

  const handleSetDiscard = (f: () => void | null) => {
    setDiscard(() => f);
  };

  const handleSave = () => {
    if (titleRef.current.length === 0) {
      setTitleError('Title is required');
      return;
    }
    save(titleRef.current);
  };

  const handleDiscard = () => {
    if (selectedZone) {
      discard?.();
      if (titleInputRef.current) {
        titleInputRef.current.value = selectedZone.label;
      }
    }
  };

  const handleClose = () => {
    if (hasUnsavedChanges) {
      confirm(
        {
          confirmButtonLabel: 'Discard',
          rejectButtonLabel: 'Go back',
          title: 'You have unsaved changes',
          message: 'Are you sure you want to discard your changes?',
        },
        onClose,
      );
      return;
    }
    onClose();
  };

  useEffect(() => {
    titleRef.current = selectedZone?.label ?? '';
    setRadius(selectedZone?.radius ?? DEFAULT_RADIUS);
    if (titleInputRef.current) {
      titleInputRef.current.value = selectedZone?.label ?? '';
    }
  }, [selectedZone, titleInputRef]);

  const showControls = type !== null || hasUnsavedChanges || !!selectedZone;

  return (
    <BasicModal
      open={isOpen}
      onClose={handleClose}
      maxWidth="920px"
      innerSx={{
        p: { xxs: 2, xs: 3 },
        width: { xxs: '100%', newLg: '90%' },
        height: { xxs: '100dvh', newLg: 'unset' },
        maxHeight: { newLg: 'unset' },
        borderRadius: { xxs: '0', newLg: '8px' },
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        sx={({ spacing }) => ({
          pt: { newMd: spacing(3) },
          gap: { xxs: spacing(2), xs: spacing(3) },
        })}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          sx={({ spacing }) => ({
            flexDirection: { xxs: 'column', newMd: 'row' },
            gap: { xxs: spacing(2), xs: spacing(3) },
          })}
        >
          <Box>
            <TextField
              variant="standard"
              size="medium"
              placeholder="Safe zone title"
              onChange={handleTitleChange}
              defaultValue={selectedZone?.label ?? ''}
              error={!!titleError}
              slotProps={{
                input: {
                  sx: {
                    '& input': {
                      pb: 0,
                    },
                  },
                },
              }}
            />
            {titleError && (
              <HelperText sx={{ pl: 0, height: 16 }} error>
                {titleError}
              </HelperText>
            )}
          </Box>
          <SearchInput />
        </Box>
        <NewSafeZoneContext.Provider
          value={{
            type,
            setType,
            radius,
            setRadius,
            setSave: handleSetSave,
            onClose,
            setIsSaveActive,
            setDiscard: handleSetDiscard,
            setIsLoading: (value) => setIsLoading(value),
            setHasUnsavedChanges,
            title: sharedTitle,
            onSave,
          }}
        >
          <EditMap editedZone={selectedZone} />
          <Box
            display="flex"
            justifyContent="space-between"
            gap={4}
            sx={{
              flexDirection: { xxs: 'column', newMd: 'row' },
            }}
          >
            <Box
              sx={({ spacing }) => ({
                width: { xxs: '100%', newMd: spacing(45) },
              })}
            >
              {type !== null && type !== RegionsType.POLYGON_TYPE && (
                <RadiusSlider
                  defaultRadius={radius}
                  onChange={handleRadiusChange}
                />
              )}
            </Box>
            <Box display="flex" justifyContent="flex-end">
              {showControls && (
                <>
                  {!!selectedZone && (
                    <Button
                      disabled={!hasUnsavedChanges}
                      onClick={handleDiscard}
                      color="primary"
                      size="medium"
                      variant="outlined"
                      sx={{ mr: 2 }}
                    >
                      Discard
                    </Button>
                  )}
                  <Button
                    disabled={!isSaveActive}
                    onClick={handleSave}
                    color="primary"
                  >
                    Save
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </NewSafeZoneContext.Provider>
      </Box>
      {isLoading && <Backdrop sx={{ zIndex: 9990 }} open={isLoading} />}
    </BasicModal>
  );
};

export default AddSafeZoneModal;
