import { Button, CircularProgress, Grid2, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { useGetPasswordResetEmailQuery } from 'store/api/admin.api';
import { useRequestPasswordResetMutation } from 'store/api/auth.api';

interface Props {
  accountId: number;
}

const PasswordReset = ({ accountId }: Props) => {
  const { data: email, isFetching } = useGetPasswordResetEmailQuery({
    accountId,
  });
  const [resetPassword, { isLoading }] = useRequestPasswordResetMutation();

  const handleClick = () => {
    if (email) {
      resetPassword({ email }).then((result) => {
        if ((result as Record<string, unknown>).error) {
          toast('There was an error. Please try again.', { type: 'error' });
          return;
        }

        toast('The password reset link was sent succesfully!', {
          type: 'success',
        });
      });
    }
  };

  return !isFetching ? (
    email ? (
      <Grid2 container rowGap={1} alignItems="center">
        <Grid2 size={12}>
          <Typography variant="h6" my={1}>
            Password Reset
          </Typography>
        </Grid2>
        <Grid2 size={6}>Send password reset link</Grid2>
        <Grid2 size={6}>
          <Button onClick={handleClick} disabled={isLoading}>
            Send
          </Button>
        </Grid2>
      </Grid2>
    ) : (
      <>
        <Typography variant="h6" my={1}>
          Password Reset
        </Typography>
        <Typography>Could not fetch the email for this profile.</Typography>
      </>
    )
  ) : (
    <CircularProgress sx={{ m: 4 }} />
  );
};

export default PasswordReset;
