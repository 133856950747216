import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  alpha,
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuItemProps,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import IconButton, { IconButtonProps } from 'components/IconButton/IconButton';
import { Icon, IconifyIcon } from '@iconify/react';
import { Link } from 'react-router-dom';

export const SidebarWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  minWidth: theme.spacing(30),
  height: '100%',
  backgroundColor: theme.palette.blue.dark,
  overflowY: 'auto',
}));

export const SidebarMenuItem = styled(
  (props: MenuItemProps & { isSubItem?: boolean }) => <MenuItem {...props} />,
  { shouldForwardProp: (prop) => prop !== 'isSubItem' },
)(({ theme, isSubItem }) => ({
  transition: 'background-color 0.1s',
  paddingLeft: isSubItem ? theme.spacing(2.5) : theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),

  '&:hover': {
    backgroundColor: theme.palette.hover.dark,
  },
}));

export const StyledLink = styled(Link)({
  display: 'block',
  transition: 'border-color 0.1s',
});

export const SidebarAccordion = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: 'none',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

export const SidebarAccordionArrow = styled(
  ({
    isOpen,
    ...rest
  }: Omit<IconButtonProps, 'icon'> & { isOpen?: boolean }) => (
    <IconButton
      icon="material-symbols:keyboard-arrow-right"
      width={24}
      height={24}
      disableRipple
      sx={{
        transition: 'transform 0.2s',
        transform: isOpen ? 'rotate(90deg)' : undefined,
        ':hover': {
          background: 'none',
        },
      }}
      {...rest}
    />
  ),
)(({ theme }) => ({
  color: theme.palette.common.white,
  padding: 0,
}));

export const SidebarAccordionDetails = styled(AccordionDetails)(
  ({ theme }) => ({
    padding: 0,
    backgroundColor: theme.palette.blue.darkHover,
  }),
);

export const SidebarMenuTitle = ({ children }: { children: string }) => {
  const theme = useTheme();

  return (
    <Box pl={theme.spacing(3)} py={theme.spacing(1.5)}>
      <Typography
        variant="body1"
        component="h6"
        color={theme.palette.blue.light}
        textTransform="uppercase"
        fontSize={theme.spacing(1.5)}
        lineHeight={theme.spacing(2)}
      >
        {children}
      </Typography>
    </Box>
  );
};

export const SidebarMenuItemIcon = ({
  icon,
}: {
  icon: string | IconifyIcon;
}) => {
  const theme = useTheme();

  return (
    <ListItemIcon>
      <Icon
        icon={icon}
        width={theme.spacing(3)}
        height={theme.spacing(3)}
        color={theme.palette.common.white}
      />
    </ListItemIcon>
  );
};

export const SidebarMenuItemText = ({
  children,
  highlighted,
}: {
  children: string;
  highlighted?: boolean;
}) => {
  const theme = useTheme();

  return (
    <ListItemText
      slotProps={{
        primary: {
          color: theme.palette.common.white,
          fontWeight: highlighted ? 600 : 300,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        },
      }}
      sx={{ flexGrow: 1, pr: 1 }}
    >
      {children}
    </ListItemText>
  );
};

export const SidebarMenuDivider = styled(Divider)(({ theme }) => ({
  borderColor: alpha(theme.palette.common.white, 0.1),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));
