import { Comparator, TriggerGroup } from 'utils/triggers';
import {
  AlerterRecipientManager,
  PatternType,
  RawAlerterRecipientManager,
} from './recipient-manager';
import { AlerterScheduler } from './scheduler';
import { Day } from 'utils/weekdays';

export * from './scheduler';
export * from './recipient-manager';
export * from './triggers';
export * from './reminders';

export type SourceStreamType = 'account' | 'any_wearable' | 'any_device';
export type SourceType = 'PatientProfile' | 'user';

export type CooldownType = 'none' | 'reset' | 'time';

export const ChannelSet = {
  SMS: 'sms',
  PUSH: 'push',
  EMAIL: 'email',
  CRITICAL: 'critical',
  AUTOMATED_CALL: 'automated_call',
} as const;
export type ChannelSet = (typeof ChannelSet)[keyof typeof ChannelSet];

export interface AlerterSource {
  patientProfileId?: number;
  userId?: number;
  deviceId?: number;
  sourceType: SourceType;
  streamType: SourceStreamType;
}

export interface RawAlerterChannelSet {
  id: number;
  channels: ChannelSet[];
  allow_multiple_alerter_associations: boolean;
}

export interface RawAlerterResponse {
  result: RawAlerterChannelSet;
}

export interface RawAlerterSource {
  patient_profile_id?: number;
  device_id?: number;
  user_id?: number;
  source_type: string;
  stream_type: SourceStreamType;
}

export interface AlerterRecipientManagerSummary {
  id: number;
  name: string;
  scheduler: {
    days: Day[];
    startTime: number | null;
    endTime: number | null;
  };
}

export interface AlerterSummary {
  id: number;
  title: string;
  enabled: boolean;
  sourceStreamType: SourceStreamType;
  triggerSummary: string;
  triggerSummaryFragments: string[];
  schedulerSummary: string;
  group: TriggerGroup;
  userRecipientCount: number;
  patientsRecipientCount: number;
  recipientManagerName: string;
  widgetTargets: AlerterEventsWidgets[] | null;
  defaultWidgetTargets: AlerterEventsWidgets[] | null;
  recipientManagers: AlerterRecipientManagerSummary[];
}

export interface RawAlerterRecipientManagerSummary {
  id: number;
  name: string;
  alerter_scheduler_hash: {
    days_of_week: Day[];
    end_time: number | null;
    start_time: number | null;
  };
}

export interface RawAlerterSummary {
  id: number;
  title: string;
  enabled: boolean;
  display_group: TriggerGroup;
  alerter_source_stream_type: SourceStreamType;
  alerter_trigger_summary: string;
  alerter_trigger_summary_fragments: string[];
  alerter_recipient_manager_name: string;
  alerter_recipient_managers: RawAlerterRecipientManagerSummary[];
  alerter_scheduler_summary: string;
  user_recipients_count: number;
  patient_profile_recipients_count: number;
  generated_widget_targets: AlerterEventsWidgets[] | null;
  custom_widget_targets: AlerterEventsWidgets[] | null;
}

export interface Alerter {
  id: number;
  title: string;
  enabled: boolean;
  hasCustomMessage: boolean;
  customMessage: string | null;
  generatedMessage: string;
  source: {
    id: number;
    streamType: SourceStreamType;
    patientProfileId?: number;
    userId?: number;
    deviceId?: number;
  };
  trigger: {
    id: number;
    initialCooldown: number;
    cooldownType: CooldownType;
    cooldownTime: number | null;
  };
  scheduler: AlerterScheduler;
  recipientSets: AlerterRecipientManager;
  channelSet: AlerterChannelSet;
  targetWidget: AlerterEventsWidgets | null;
  defaultTargetWidget: AlerterEventsWidgets | null;
  availableTargetWidgets: AlerterEventsWidgets[];
  isCustomTarget?: boolean;
}

export interface RawAlerter {
  id: number;
  title: string;
  enabled: boolean;
  display_title: string;
  display_group: TriggerGroup;
  custom_message_template: string | null;
  generated_message_template: string;
  use_custom_message_template: boolean;
  generated_widget_targets: AlerterEventsWidgets[] | null;
  custom_widget_targets: AlerterEventsWidgets[] | null;
  available_widget_targets: AlerterEventsWidgets[];
  alerter_source: {
    id: number;
    stream_type: SourceStreamType;
    patient_profile_id: number;
    user_id: unknown;
    device_id: unknown;
  };
  alerter_trigger: {
    id: number;
    initial_cooldown: number;
    cooldown_type: CooldownType;
    cooldown_time: number | null;
  };
  alerter_scheduler: {
    id: number;
    serialized_schedule: {
      start_time: number | null;
      end_time: number | null;
      days_of_week: Day[];
    };
    invert_schedule: boolean;
  };
  alerter_recipient_manager: RawAlerterRecipientManager;
  alerter_recipient_managers: RawAlerterRecipientManager[];
  alerter_channel_set: {
    id: number;
    channels: ChannelSet[];
  };
  user_recipients_count: number;
  patient_profile_recipients_count: number;
  message_image_url: string | null;
}

export interface AlerterResponse {
  result: RawAlerter;
}

type AlerterSourceParams =
  | {
      alerterSourceId: number;
    }
  | {
      alerterSource: AlerterSource;
    };

interface AlerterChannelSet {
  allowMultipleAlerterAssociations: unknown;
  alerterChannels: unknown[];
}

export type CreateAlerterParams = {
  title?: string;

  alerterSourceId?: number;
  alerterSource?: AlerterSource;

  alerterTriggerId?: number;
  alerterTriggers?: unknown[];

  alerterSchedulerId?: number;
  alerterScheduler?: AlerterScheduler[];

  alerterRecipientManagerId?: number;
  alerterRecipientManager?: Omit<
    AlerterRecipientManager,
    'sets' | 'id' | 'alerters'
  > & {
    sets: { priority: number; recipients: { patternType: PatternType }[] }[];
  };

  alerterChannelSetId?: number;
  alerterChannelSet?: AlerterChannelSet;
} & AlerterSourceParams;

export type GetAlertersParams = {
  alerterSourceId?: number;
  deviceId?: number;
  userId?: number;
  patientProfileId?: number;
} & (
  | {
      alerterSourceId: number;
    }
  | {
      deviceId: number;
    }
  | {
      userId: number;
    }
  | {
      patientProfileId: number;
    }
);

export interface GetAlertersResponse {
  results: RawAlerterSummary[];
}

export interface AlerterLinkResponse {
  result: {
    success: boolean;
  };
}

export interface UpdateAlerterParams {
  id: number;
  title?: string;
  enabled?: boolean;
  hasCustomMessage?: boolean;
  customMessage?: string | null;
  generatedMessage?: string;
  recipientManagerId?: number;
  isReminder?: boolean;
  useCustomWidgetTargets?: boolean;
  customWidgetTargets?: AlerterEventsWidgets[] | null;
  messageImage?: string | null;
  messageImageUrl?: string | null;
}

export interface AlerterTemplateSummary {
  identifier: string;
  title: string;
  description: string;
  template_params: Record<
    string,
    {
      display_unit?: string;
      field_type: 'percentage' | 'integer' | 'minutes';
      default: string | null;
    }
  >[];
}

export interface GetAlerterTemplatesResponse {
  results: AlerterTemplateSummary[];
}

export type AlerterEventsWidgets =
  | 'device'
  | 'battery'
  | 'safe_zone'
  | 'heart_rate'
  | 'heart_rhythm'
  | 'blood_oxygen'
  | 'wrist_temperature'
  | 'fall'
  | 'daily_step_count'
  | 'daily_calorie_burn'
  | 'daily_sleep_time'
  | 'motion_disorder'
  | 'help_requested'
  | 'sleep_apnea'
  | 'breathing_disturbances';

export interface GetAlerterEventsByWidgetParams {
  profileId: number;
  widgets?: AlerterEventsWidgets[];
}

export type SearchAlerterEventsParams = {
  profileId: number;
  limit?: number;
  offset?: number;
  onlyUnacknowledged?: boolean;
  onlyCurrent?: boolean;
  order?: 'asc' | 'desc';
  widgets?: AlerterEventsWidgets[];
  beginsAt?: string;
  endsAt?: string;
  alerterIds?: string[];
  ruleTypes?: string[];
} & (
  | {
      widgets: AlerterEventsWidgets[];
    }
  | {
      alerterIds: string[];
    }
  | {
      ruleTypes: string[];
    }
);

export interface RawAlerterEvent {
  id: number;
  title?: string;
  alerter_title?: string;
  created_at: string;
  acknowledged_at: null | string;
  message_text: string;
  triggered_at: string;
  requires_acknowledgement: boolean;
  acknowledging_alerter_recipient_id: null | number;
  acknowledging_alerter_recipient_name: null | string;
  metric_rules_triggers: RawMetricRuleTrigger[];
  is_active?: boolean;
}

export interface RawMetricRuleTrigger {
  value: number | boolean | null;
  comparator: Comparator;
  metric_type: string;
  triggered_at: string;
  triggered_value: number | boolean | null;
}

export interface MetricRuleTrigger {
  value: number | boolean | null;
  comparator: Comparator;
  metricType: string;
  triggeredAt: string;
  triggeredValue: number | boolean | null;
}

export interface AlerterEvent {
  id: number;
  title: string;
  createdAt: string;
  acknowledgedAt: null | string;
  messageText: string;
  triggeredAt: string;
  requiresAcknowledgement: boolean;
  acknowledgingAlerterRecipientId: null | number;
  acknowledgingAlerterRecipientName: null | string;
  metricRulesTriggers: MetricRuleTrigger[];
  isActive: boolean;
  widget?: AlerterEventsWidgets;
}

export interface RawAlerterEventByWidgetResponse {
  results: Record<AlerterEventsWidgets, RawAlerterEvent[]>;
}

export interface RawAlerterSearchResponse {
  results: RawAlerterEvent[];
  meta: {
    total_alerter_events: number;
  };
}

export interface AlerterEventByWidgetResponse {
  results: Record<AlerterEventsWidgets, AlerterEvent[]>;
}
