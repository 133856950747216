import { routes } from 'nav';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  TFetchRecordInfo,
  useFetchAccountInfoQuery,
  useFetchRecordInfoQuery,
  useSaveAccountInfoMutation,
} from 'store/api/admin.api';
import ProfileLookupResultsView from './ProfileLookupResults.view';
import { Box, CircularProgress } from '@mui/material';

const ProfileLookupResults = () => {
  const profileId = Number(useParams().profileId);
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [enhancedAlerts, setEnhancedAlerts] = useState(false);
  const [powerSavingMode, setPowerSavingMode] = useState(false);
  const [hasCompletedOnboarding, setHasCompletedOnboarding] = useState(false);
  const [lastPollAt, setLastPollAt] = useState('');
  const [collectedHealthData, setCollectedHealthData] = useState(false);
  const [lastIrregularHealthAck, setLastIrregularHealthAck] = useState<
    string | null
  >(null);
  const [lastHighHealthAck, setLastHighHealthAck] = useState<string | null>(
    null,
  );
  const [lastLowHealthAck, setLastLowHealthAck] = useState<string | null>(null);
  const [lastFallAck, setLastFallAck] = useState<string | null>(null);
  const [localReminderNotifications, setLocalReminderNotifications] =
    useState(false);
  const [watchChargingReminder, setWatchChargingReminder] = useState('');
  const [performanceMode, setPerformanceMode] = useState(false);
  const [debugLogging, setDebugLogging] = useState(false);
  const [uniquePublicIdentifier, setUniquePublicIdentifier] = useState('');
  const [thirdPartyPublicIdentifier, setThirdPartyPublicIdentifier] =
    useState('');

  const [isReady, setReady] = useState(false);

  const [recordDate, setRecordDate] = useState('');
  const [recordFetchParams, setRecordFetchParams] = useState<TFetchRecordInfo>({
    type: 'current',
    accountId: profileId,
  });

  useEffect(() => {
    if (isNaN(profileId)) {
      navigate(routes.admin.lookup.href);
    }
  }, []);

  const { data: accInfoRes = {}, isFetching: isAccInfoFetching } =
    useFetchAccountInfoQuery({ id: profileId }, { skip: isNaN(profileId) });
  const [saveAccInfo, { isLoading: isAccInfoSaving }] =
    useSaveAccountInfoMutation();
  const {
    data: recordInfo,
    isFetching: isRecordInfoFetching,
    isUninitialized,
  } = useFetchRecordInfoQuery(recordFetchParams, {
    skip:
      isAccInfoFetching ||
      (accInfoRes.isLovedOne && !accInfoRes.didCompleteManagedOnboarding),
  });

  useEffect(() => {
    setFirstName(accInfoRes.firstName || '');
    setLastName(accInfoRes.lastName || '');
    setEnhancedAlerts(accInfoRes.enhancedAlerts || false);
    setPowerSavingMode(accInfoRes.powerSavingMode || false);
    setLastPollAt(accInfoRes.lastPollAt || '');
    setCollectedHealthData(accInfoRes.collectedHealthData || false);
    setLastIrregularHealthAck(accInfoRes.lastIrregularHealthAck || null);
    setLastHighHealthAck(accInfoRes.lastHighHealthAck || null);
    setLastLowHealthAck(accInfoRes.lastLowHealthAck || null);
    setLastFallAck(accInfoRes.lastFallAck || '');
    setLocalReminderNotifications(
      accInfoRes.localReminderNotifications || false,
    );
    setWatchChargingReminder(accInfoRes.watchChargingReminder || '');
    setPerformanceMode(accInfoRes.performanceMode || false);
    setDebugLogging(accInfoRes.debugLogging || false);
    setUniquePublicIdentifier(accInfoRes.uniquePublicIdentifier || '');
    setThirdPartyPublicIdentifier(accInfoRes.thirdPartyPublicIdentifier || '');
    setHasCompletedOnboarding(accInfoRes.didCompleteManagedOnboarding || false);

    setReady(true);
  }, [accInfoRes]);

  useEffect(() => {
    setRecordDate(recordInfo?.generated_at || '');

    if (!recordInfo && !isUninitialized) {
      setRecordFetchParams({
        type: 'current',
        accountId: profileId,
      });
    }
  }, [JSON.stringify(recordInfo)]);

  useEffect(() => {
    setRecordFetchParams((oldParams) => ({
      ...oldParams,
      accountId: profileId,
    }));
  }, [profileId]);

  const onSavePress = () => {
    saveAccInfo({
      accountId: profileId,
      firstName,
      lastName,
      enhancedAlerts,
      powerSavingMode,
      lastPollAt,
      collectedHealthData,
      lastIrregularHealthAck: lastIrregularHealthAck || '',
      lastHighHealthAck: lastHighHealthAck || '',
      lastLowHealthAck: lastLowHealthAck || '',
      lastFallAck: lastFallAck || '',
      localReminderNotifications,
      watchChargingReminder,
      performanceMode,
      debugLogging,
      didCompleteManagedOnboarding: hasCompletedOnboarding,
      thirdPartyPublicIdentifier,
    });
  };

  const onRecordDateAccept = () => {
    setRecordFetchParams({
      type: 'forDate',
      accountId: profileId,
      date: recordDate,
    });
  };

  const onPreviousPress = () => {
    if (recordInfo?.id) {
      setRecordFetchParams({
        type: 'previous',
        currentId: recordInfo?.id,
      });
    }
  };

  const onNextPress = () => {
    if (recordInfo?.id) {
      setRecordFetchParams({
        type: 'next',
        currentId: recordInfo?.id,
      });
    }
  };

  const isAccInfoLoading = isAccInfoFetching || isAccInfoSaving;

  if (isAccInfoFetching) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ProfileLookupResultsView
      profileId={profileId || 0}
      isLovedOne={accInfoRes.isLovedOne}
      profileInfo={{
        isLoading: isAccInfoLoading,
        isReady,
        onSavePress,
        firstName: {
          value: firstName,
          onChange: (e) => setFirstName(e.target.value),
        },
        lastName: {
          value: lastName,
          onChange: (e) => setLastName(e.target.value),
        },
        emergencyTracking: {
          value: accInfoRes.emergencyTracking,
        },
        enhancedAlerts: {
          value: enhancedAlerts,
          onChange: () => setEnhancedAlerts(!enhancedAlerts),
        },
        powerSavingMode: {
          value: powerSavingMode,
          onChange: () => setPowerSavingMode(!powerSavingMode),
        },
        lastPollAt: {
          value: lastPollAt,
        },
        collectedHealthData: {
          value: collectedHealthData,
          onChange: () => setCollectedHealthData(!collectedHealthData),
        },
        lastIrregularHealthAck: {
          value: lastIrregularHealthAck
            ? new Date(lastIrregularHealthAck)
            : null,
          onChange: (date) => setLastIrregularHealthAck(date),
        },
        lastHighHealthAck: {
          value: lastHighHealthAck ? new Date(lastHighHealthAck) : null,
          onChange: (date) => setLastHighHealthAck(date),
        },
        lastLowHealthAck: {
          value: lastLowHealthAck ? new Date(lastLowHealthAck) : null,
          onChange: (date) => setLastLowHealthAck(date),
        },
        lastFallAck: {
          value: lastFallAck ? new Date(lastFallAck) : null,
          onChange: (date) => setLastFallAck(date),
        },
        localReminderNotifications: {
          value: localReminderNotifications,
          onChange: () =>
            setLocalReminderNotifications(!localReminderNotifications),
        },
        watchChargingReminder: {
          value: watchChargingReminder,
          onChange: (e) => setWatchChargingReminder(e.target.value),
        },
        performanceMode: {
          value: performanceMode,
          onChange: () => setPerformanceMode(!performanceMode),
        },
        debugLogging: {
          value: debugLogging,
          onChange: () => setDebugLogging(!debugLogging),
        },
        uniquePublicIdentifier: {
          value: accInfoRes.uniquePublicIdentifier,
        },
        thirdPartyPublicIdentifier: {
          value: thirdPartyPublicIdentifier,
          onChange: (e) => setThirdPartyPublicIdentifier(e.target.value),
        },
        didCompleteManagedOnboarding: {
          value: hasCompletedOnboarding,
          onChange: () => setHasCompletedOnboarding(!hasCompletedOnboarding),
        },
      }}
      recordInfo={recordInfo}
      isRecordLoading={isRecordInfoFetching}
      recordDate={{
        value: recordDate,
        onChange: (date) => setRecordDate(date || ''),
        onAccept: onRecordDateAccept,
      }}
      onPreviousPress={onPreviousPress}
      onNextPress={onNextPress}
    />
  );
};

export default ProfileLookupResults;
