import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'nav';
import { Paper, Button, Box, Select, MenuItem } from '@mui/material';
import VInput from 'components/VInput';
import ResultsTable from './ResultsTable';
import { SearchByOption, selectOptions } from './types';
import { useSearch } from './use-search';

const ProfileLookup = () => {
  const navigate = useNavigate();

  const { isLoading, rows, search } = useSearch();

  const [searchString, setSearchString] = useState('');
  const [searchByOption, setSearchByOption] = useState<SearchByOption>('email');

  const isSearchDisabled = !searchString.length || isLoading;

  const onSearchPress = () => {
    search(searchString.trim(), searchByOption);
  };

  const onResultBtnPress = (id: number) => {
    navigate(routes.admin.lookupResults.href(id));
  };

  return (
    <Paper sx={{ padding: 4 }}>
      <Box display="flex" alignItems="end">
        <VInput
          text="Search string"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          disabled={isLoading}
          size="small"
          sx={{
            width: ({ spacing }) => spacing(30),
            maxHeight: ({ spacing }) => spacing(5.125),
            mt: '-1px',
          }}
        />
        <Select
          sx={{
            mb: 1,
            mr: 1,
            height: ({ spacing }) => spacing(5.25),
            width: ({ spacing }) => spacing(30),
          }}
          size="small"
          fullWidth
          disabled={isLoading}
          value={searchByOption}
          onChange={(e) => setSearchByOption(e.target.value as SearchByOption)}
        >
          {selectOptions.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.displayName}
            </MenuItem>
          ))}
        </Select>
        <Button
          sx={{ mb: 1 }}
          onClick={onSearchPress}
          disabled={isSearchDisabled}
        >
          Search
        </Button>
      </Box>
      <div>
        <ResultsTable
          rows={rows}
          isLoading={isLoading}
          onRowBtnClick={onResultBtnPress}
          sx={{ mt: 3 }}
        />
      </div>
    </Paper>
  );
};

export default ProfileLookup;
