import { gridClasses, useGridApiRef } from '@mui/x-data-grid-pro';
import { useCallback, useEffect } from 'react';

export const useTableScroll = () => {
  const apiRef = useGridApiRef();

  const content = apiRef.current?.rootElementRef?.current?.querySelector(
    `.${gridClasses.virtualScroller}`,
  );

  const handleScroll = useCallback(() => {
    if (content) {
      if (content.scrollLeft > 0) {
        content.classList.add('scrolled');
        return;
      }
      content.classList.remove('scrolled');
    }
  }, [content]);

  useEffect(() => {
    if (content) {
      content.addEventListener('scroll', handleScroll);
      return () => content.removeEventListener('scroll', handleScroll);
    }
  }, [content]);

  return {
    apiRef,
  };
};
