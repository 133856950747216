import { Divider, IconButton, styled } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import {
  setAlertHistoryFilterCriteria,
  setAlertIds,
  setWidgets,
} from 'services/alert-history-filters';
import { AlerterEventsWidgets, AlerterSummary } from 'types/alerters';
import FilterTypeSelect from './FilterTypeSelect';
import NestedSelect from './NestedSelect';
import { useGetAlertersQuery } from 'store/api/alerters.api';
import { useParams, useSearchParams } from 'react-router-dom';
import { removeDuplicates } from 'utils/remove-duplicate-strings';
import { TriggerGroup } from 'utils/triggers';
import { Icon } from '@iconify/react';
import { clearFilters } from 'assets/iconify';
import DateRangePicker from './DateRangePicker';

const getOptionsFromAlertList = (
  data?: Record<TriggerGroup | 'uncategorized', AlerterSummary[]>,
) => {
  if (!data)
    return {
      groupOptions: [],
      widgetOptions: [],
    };

  const groupOptions = Object.entries(data).map(([name, items]) => ({
    label: name,
    value: name,
    children: items.map(({ title, id }) => ({
      label: title,
      value: `${id}`,
    })),
  }));

  const widgetOptions = Object.entries(data).map(([name, items]) => {
    const widgets = removeDuplicates(
      items.reduce((prev, curr) => {
        if (!curr.widgetTargets) return prev;
        return [...prev, ...curr.widgetTargets];
      }, [] as AlerterEventsWidgets[]),
    );

    return {
      label: name,
      value: name,
      children: widgets.map((widget) => ({
        label: widget.replaceAll('_', ' '),
        value: widget,
      })),
    };
  });

  return {
    groupOptions,
    widgetOptions,
  };
};

const Root = styled('div')`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(3)};
  row-gap: ${({ theme }) => theme.spacing(2)};
  flex-wrap: nowrap;

  ${({ theme }) => theme.breakpoints.down('newMd')} {
    flex-direction: column;
  }
`;

const OptionSelectorWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const AlertHistory = () => {
  const { profileId } = useParams();
  const { data } = useGetAlertersQuery({ patientProfileId: Number(profileId) });

  const { groupOptions, widgetOptions } = useMemo(
    () => getOptionsFromAlertList(data),
    [data],
  );

  const [params, setParams] = useSearchParams();

  const [selectedFilter, setSelectedFilter] = useState<'widget' | 'group'>(
    'group',
  );
  const [selected, setSelected] = useState<string[]>([]);

  const updateFilters = (updateWith?: string[], forceWidget?: boolean) => {
    if (selectedFilter === 'widget' || forceWidget) {
      setWidgets((updateWith ?? selected) as AlerterEventsWidgets[]);
      return;
    }
    setAlertIds(updateWith ?? selected);
  };

  const handleSelectChange = (values: string[]) => {
    setSelected(values);
  };

  const handleFilterSelect = (value: 'widget' | 'group') => {
    setAlertHistoryFilterCriteria(value);
    setSelected([]);
    setSelectedFilter(value);
  };

  const clearSelection = () => {
    setSelected([]);
    updateFilters([]);
  };

  useEffect(() => {
    if (params.has('historyFor')) {
      setSelectedFilter('widget');
      setAlertHistoryFilterCriteria('widget');
      setSelected([params.get('historyFor') as string]);
      updateFilters([params.get('historyFor') as string], true);
      setParams({}, { replace: true });
      return;
    }
  }, [params]);

  return (
    <Root>
      <DateRangePicker />
      <Divider
        orientation="vertical"
        flexItem
        sx={{
          mt: 4.5,
          mb: 0.5,
          display: {
            xxs: 'none',
            newMd: 'block',
          },
        }}
      />
      <div>
        <OptionSelectorWrapper>
          <FilterTypeSelect
            value={selectedFilter}
            onOptionChange={handleFilterSelect}
          />
          <IconButton
            color="error"
            onClick={clearSelection}
            sx={{
              p: 0.5,
              maxHeight: ({ spacing }) => spacing(3.5),
              display: selected.length > 0 ? 'block' : 'none',
            }}
          >
            <Icon width={20} height={20} icon={clearFilters.icons.normal} />
          </IconButton>
        </OptionSelectorWrapper>
        <NestedSelect
          value={selected}
          onClose={updateFilters}
          options={selectedFilter === 'group' ? groupOptions : widgetOptions}
          onChange={handleSelectChange}
        />
      </div>
    </Root>
  );
};

export default AlertHistory;
