import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { caregiverRole, setCaregiverRole } from 'services/caregiver-role';
import { useAppDispatch, useAppSelector } from 'store';
import { useGetAllQuery } from 'store/api/devices.api';
import {
  useGetLinkedProfilesQuery,
  useGetProfileQuery,
} from 'store/api/profiles.api';
import { updateSelectedUser } from 'store/reducers/dashboard/dashboard.slice';

type Args = {
  deviceId?: number;
};

/*
  This hook is used to get the profile data and devices data of the selected user
  and update the redux state with the selected user's accountId, deviceId, and name.
  As well as the caregiver's role for this specific profile.
*/
export const useProfileData = (externalArgs?: Args) => {
  const dispatch = useAppDispatch();
  const { profileId } = useParams();
  const caregiverId = useAppSelector((state) => state.user.accountId);

  const { data: profileData } = useGetProfileQuery(
    {
      id: parseInt(profileId ?? '0'),
    },
    { skip: !profileId },
  );

  const { data: devicesData = [], isLoading: areDevicesLoading } =
    useGetAllQuery({
      id: parseInt(profileId ?? '0'),
    });

  const { data: linkedProfilesData } = useGetLinkedProfilesQuery(
    { caregiverId: Number(caregiverId) },
    { skip: !caregiverId },
  );

  useEffect(() => {
    if (profileData && devicesData.length > 0) {
      const activeDevice =
        devicesData.find((device) => device.isActive) ?? devicesData[0];

      dispatch(
        updateSelectedUser({
          accountId: profileData.result.id,
          deviceId: externalArgs?.deviceId ?? activeDevice?.deviceId ?? 0,
          name: [profileData.result.first_name, profileData.result.last_name]
            .filter((name) => name)
            .join(' '),
        }),
      );
      return;
    }

    if (profileData && !areDevicesLoading) {
      dispatch(
        updateSelectedUser({
          accountId: profileData.result.id,
          deviceId: 0,
          name: [profileData.result.first_name, profileData.result.last_name]
            .filter((name) => name)
            .join(' '),
        }),
      );
    }
  }, [profileData, devicesData, areDevicesLoading]);

  useEffect(() => {
    if (caregiverRole.value === 'admin') return;

    if (linkedProfilesData && profileId) {
      const selectedProfile = linkedProfilesData.results.find(
        (profile) => profile.account_id === parseInt(profileId),
      );

      if (selectedProfile) {
        setCaregiverRole(
          selectedProfile.subscriber_is_primary ? 'manager' : 'viewer',
        );
      }
    }
  }, [linkedProfilesData, profileId]);
};
