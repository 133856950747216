import { usePagination } from 'hooks/use-pagination';
import { routes } from 'nav';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import {
  useGetDashboardDataQuery,
  useLazyGetCustomRecordQuery,
} from 'store/api/dashboard.api';
import { setPaginationTotal } from 'store/reducers/filters/filters.slice';
import { PatientRecord } from 'types/patient';
import { useRecordsColumns } from './use-records-columns';

export const useRecordsTable = () => {
  const [isThrottling, setIsThrottling] = useState(true);
  const latestPageRef = useRef<number>(0);

  const { profileId } = useParams();

  const {
    pageIndex: page,
    limit,
    offset,
    handlePaginationModelChange,
  } = usePagination(50, 0);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { requestFields, columns } = useRecordsColumns();

  const [beginsAt, endsAt] = useAppSelector((state) => state.filters.dateRange);

  const { isLoading: isDashboardDataLoading, data: dashboardData } =
    useGetDashboardDataQuery({
      account_id: Number(profileId),
    });

  useEffect(() => {
    if (!dashboardData?.deviceId && !isDashboardDataLoading) {
      navigate(routes.private.patientsOverview.href);
    }
  }, [dashboardData?.deviceId, isDashboardDataLoading]);

  const [
    fetchData,
    {
      data = {
        rows: [] as PatientRecord[],
        totalNumber: 0,
        displayedNumber: 0,
      },
      isFetching,
      isLoading,
    },
  ] = useLazyGetCustomRecordQuery({
    refetchOnReconnect: true,
  });

  const refetch = () => {
    if (dashboardData?.deviceId === undefined) return;

    fetchData({
      patient_profile_id: Number(profileId),
      device_id: dashboardData?.deviceId,
      begins_at: beginsAt ?? '',
      ends_at: endsAt ?? '',
      display_columns: requestFields,
      limit,
      offset,
    });

    setIsThrottling(false);
  };

  useEffect(() => {
    dispatch(setPaginationTotal(data.totalNumber));
  }, [data.totalNumber]);

  useEffect(() => {
    if (dashboardData) {
      setIsThrottling(true);
      const lastRequest = setTimeout(refetch, 2000);
      if (page === latestPageRef.current) {
        handlePaginationModelChange({ page: 0, pageSize: limit });
        latestPageRef.current = 0;
      } else {
        latestPageRef.current = page;
      }
      return () => clearTimeout(lastRequest);
    }
  }, [requestFields, beginsAt, endsAt, page, limit, dashboardData]);

  return {
    page,
    limit,
    columns,
    rows: data.rows,
    displayedNumber: data.displayedNumber,
    isLoading: isLoading || isFetching || isThrottling,
    handlePaginationModelChange,
    requestFields,
    totalNumber: data.totalNumber,
  };
};
