import { Icon } from '@iconify/react';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { StyledIcon } from 'components/_extra/Buttons/styled';
import { routes } from 'nav';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AlerterEventsWidgets } from 'types/alerters';

type Props = {
  dataHistoryHref?: string;
  profileId?: string;
  widgetType?: AlerterEventsWidgets;
  hasAlert?: boolean;
};

export const HistoryButton = ({
  dataHistoryHref,
  hasAlert,
  profileId,
  widgetType,
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (href: string) => {
    navigate(href);
    handleClose();
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: 12,
        left: 12,
      }}
    >
      <IconButton onClick={handleClick}>
        <StyledIcon icon="material-symbols:more-vert" />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {hasAlert && widgetType && profileId && (
          <MenuItem
            onClick={() =>
              handleNavigate(routes.private.alerter.href(profileId, widgetType))
            }
            sx={{ display: 'flex', gap: ({ spacing }) => spacing(2) }}
          >
            Alert History{' '}
            <Icon icon="material-symbols:arrow-right-alt-rounded" />
          </MenuItem>
        )}
        {dataHistoryHref && (
          <MenuItem
            onClick={() => handleNavigate(dataHistoryHref)}
            sx={{ display: 'flex', gap: ({ spacing }) => spacing(2) }}
          >
            Data History{' '}
            <Icon icon="material-symbols:arrow-right-alt-rounded" />
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};
