import { MouseEvent, useEffect, useState } from 'react';
import { AlertCardProps } from './AlertCard';
import {
  Divider,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  styled,
} from '@mui/material';
import { Icon } from '@iconify/react';
import { copy, moreVert, trash } from 'assets/iconify';
import theme from 'styles/theme';

const Root = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
  },
}));

const StyledIcon = styled(Icon)(({ theme }) => ({
  width: theme.spacing(2.5),
  height: theme.spacing(2.5),
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.primary.main,
  gap: theme.spacing(2),
  justifyContent: 'space-between',
  width: theme.spacing(15),
}));

const SmallScreenControls = ({
  onCopy,
  onEdit,
  onRemove,
  removeLabel = 'Delete',
}: Pick<AlertCardProps, 'onCopy' | 'onEdit' | 'onRemove'> & {
  removeLabel?: string;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (anchorEl) {
      handleMenuClose();
      return;
    }
    handleMenuOpen(e);
  };

  const handlePropagation = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const closeOnAction = (func?: () => void) => {
    return () => {
      handleMenuClose();
      func?.();
    };
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > theme.breakpoints.values.sm) {
        handleMenuClose();
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Root onClick={handlePropagation}>
      <IconButton
        size="small"
        onClick={handleClick}
        sx={{ p: 0.5, color: ({ palette }) => palette.primary.light }}
      >
        <Icon icon={moreVert.icons.normal} width={24} height={24} />
      </IconButton>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList dense sx={{ py: 0 }}>
          <StyledMenuItem onClick={closeOnAction(onEdit)}>Open</StyledMenuItem>
          <Divider />
          <StyledMenuItem onClick={closeOnAction(onCopy)}>
            Copy <StyledIcon icon={copy.icons.normal} />
          </StyledMenuItem>
          <StyledMenuItem
            onClick={closeOnAction(onRemove)}
            sx={{ color: ({ palette }) => palette.red.main }}
          >
            {removeLabel} <StyledIcon icon={trash.icons.normal} />
          </StyledMenuItem>
        </MenuList>
      </Menu>
    </Root>
  );
};

export default SmallScreenControls;
