import { Device } from 'models/devices.model';

export const transformDeviceData = (device: Device) => {
  return {
    id: device.id,
    name: device.name,
    accountId: device.account_id,
    isActive: device.active,
    model: device.model,
    system: device.system,
    identifiers: device.identifiers,
    deviceId: device.device_id,
  };
};
