import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const sleepApnea: IconifyJSON = {
  prefix: 'sleep-apnea',
  icons: {
    normal: {
      ...defaultSizes,
      body: `
        <g mask="url(#mask0_13314_2102)">
          <path d="M5.29593 3.44659C7.8326 1.4953 11.4735 0.767358 14.8518 2.40426C16.8887 3.39127 18.3697 4.91802 18.9838 6.46882C19.548 7.89349 19.582 9.3544 19.584 10.5167L21.7747 13.5835C22.0181 13.9244 21.9911 14.4139 21.6592 14.7192C20.7608 15.5457 19.8385 15.7087 19.0268 15.7408L18.7111 18.9879C18.6227 19.898 17.8069 20.5599 16.8981 20.4589L14.2795 20.1679V22.5H8.7V18.3C8.7 17.582 9.28203 17 10 17H14.5L15 15.6H10C9.52872 15.6 9.08563 15.7207 8.7 15.933V14C8.7 13.282 9.28203 12.7 10 12.7H16.5L17 11.3H10C8.50883 11.3 7.3 12.5088 7.3 14V22.5H4.24877V17.363C4.24877 16.2999 3.74512 15.3589 3.2407 14.4165C2.88302 13.7482 2.52494 13.0792 2.34576 12.3654C1.45258 8.80717 2.74961 5.40529 5.29593 3.44659Z" fill="currentColor"/>
        </g>
  `,
    },
  },
};
