import { sleepApnea } from 'assets/iconify';
import { DataStatus } from 'types/enum/data-status.enum';

export const getSleepApneaStatus = (value?: boolean, hasAlert?: boolean) => {
  if (typeof value === 'undefined') {
    return {
      status: DataStatus.NO_DATA,
      label: 'No data',
      icon: sleepApnea.icons.normal,
    };
  }

  if (value) {
    return {
      status: DataStatus.NEGATIVE,
      label: 'Detected',
      icon: sleepApnea.icons.normal,
    };
  }

  return {
    status: hasAlert ? DataStatus.POSITIVE : DataStatus.NO_DATA,
    label: hasAlert ? 'Normal' : 'No data',
    icon: sleepApnea.icons.normal,
  };
};
