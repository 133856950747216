import { breathingDisturbance } from 'assets/iconify';
import { DataStatus } from 'types/enum/data-status.enum';

export const getBreathingDisturbancesStatus = (
  value?: number | null,
  hasAlert?: boolean,
) => {
  if (!value) {
    return {
      icon: breathingDisturbance.icons.normal,
      status: DataStatus.NO_DATA,
      label: 'None',
      value: '%',
    };
  }

  return {
    icon: breathingDisturbance.icons.normal,
    status: hasAlert ? DataStatus.POSITIVE : DataStatus.NO_DATA,
    label: hasAlert ? 'Normal' : '',
    value: `${value}%`,
  };
};
