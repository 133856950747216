import { styled, Typography } from '@mui/material';
import { gridClasses, GridColDef } from '@mui/x-data-grid-pro';
import { IconButton } from 'components/IconButton';
import { Table } from 'components/Table';
import { CaregiverDraft } from './types';

const commonColumnProperties: Partial<GridColDef> = {
  editable: false,
  filterable: false,
  sortable: false,
  hideable: false,
  flex: 1,
  disableColumnMenu: true,
};

interface Props {
  rows?: CaregiverDraft[];
  isLoading?: boolean;
  onRowDelete?: (id: number | string) => void;
}

const StyledTable = styled(Table)`
  width: 100%;
  .${gridClasses.cell} {
    padding: 0;
    padding-left: 12px;
    max-width: 100%;
  }
`;

const CaregiverTable = ({ onRowDelete, isLoading, rows }: Props) => {
  const columns: GridColDef<CaregiverDraft>[] = [
    {
      ...commonColumnProperties,
      field: 'firstName',
      headerName: 'First Name',
      minWidth: 120,
      renderCell: ({ row }) => (
        <Typography variant="body2" color="primary" className="patient-name">
          {row.firstName}
        </Typography>
      ),
    },
    {
      ...commonColumnProperties,
      field: 'lastName',
      headerName: 'Last Name',
      minWidth: 120,
      renderCell: ({ row }) => (
        <Typography variant="body2" color="primary" className="patient-name">
          {row.lastName}
        </Typography>
      ),
    },
    {
      ...commonColumnProperties,
      field: 'email',
      headerName: 'Email',
      minWidth: 200,
      renderCell: ({ row }) => (
        <Typography variant="body2" color="primary" className="patient-name">
          {row.email}
        </Typography>
      ),
    },
    {
      ...commonColumnProperties,
      field: 'delete',
      headerName: '',
      minWidth: 30,
      renderCell: ({ row }) => (
        <IconButton
          icon="material-symbols:delete-forever-outline"
          size="small"
          sx={{ p: 0 }}
          onClick={() => onRowDelete?.(row.id)}
          color="error"
        />
      ),
    },
  ];

  return (
    <StyledTable
      columns={columns}
      rows={rows || []}
      pagination={false}
      hideFooter
      rowHeight={30}
      columnHeaderHeight={50}
      loading={isLoading}
    />
  );
};

export default CaregiverTable;
