import { heartRate } from 'assets/iconify';
import { DataStatus } from 'types/enum/data-status.enum';

export const getHeartRhythmStatus = (
  value: number | null | undefined,
  isActiveIssue?: boolean,
  isDisabled?: boolean,
  aFibValue?: number | null,
) => {
  const genericValues = {
    status: isActiveIssue ? DataStatus.NEGATIVE : DataStatus.POSITIVE,
    icon: heartRate.icons.irregular,
  };

  if (typeof aFibValue === 'number') {
    return {
      ...genericValues,
      label: 'A-fib Burden',
      value: `${aFibValue}%`,
    };
  }

  if (typeof value === 'number') {
    return {
      ...genericValues,
      label: '',
      value: `${Math.floor(value * 100)}%`,
    };
  }

  if (isDisabled) {
    return {
      ...genericValues,
      status: DataStatus.DISABLED,
      label: 'Disabled',
    };
  }

  return {
    ...genericValues,
    label: isActiveIssue ? 'Irregular' : 'Normal',
  };
};
