/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { SelectChangeEvent } from '@mui/material';
import {
  Ref,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { useAdminStatusQuery } from 'store/api/auth.api';
import {
  MetricData,
  updateEditedRule,
} from 'store/reducers/alerter/alerter.slice';
import { updateReminderCondition } from 'store/reducers/reminder/reminder.slice';
import { DeltaValue } from 'types/alerters';
import {
  TriggerGroup,
  TriggerType,
  getTriggerGroupByType,
  getTriggerGroups,
  getTriggerType,
} from 'utils/triggers';

const groups = getTriggerGroups();

export interface FieldsData {
  type: TriggerType;
  group: TriggerGroup;
  rule: string;
  value?: number;
  zones?: number[] | null;
  baseline?: number;
}

export const useMetricRule = (
  id: number | undefined,
  defaultValues: MetricData,
  ref: Ref<{ getFieldsData: () => FieldsData; reset?: () => void }>,
  isReminder?: boolean,
  onChange?: () => void,
) => {
  const reducerName = isReminder ? 'reminder' : 'alerter';

  const editedId = useAppSelector(
    (state) => state[reducerName].trigger.editedRule?.id,
  );
  const isSafeZoneEmpty = useAppSelector(
    (state) => state[reducerName].trigger.emptySafeZones,
  );
  const originalRule = useAppSelector(
    (state) => state[reducerName].trigger.editedRule?.originalRule,
  );

  const { accountId } = useAppSelector((state) => state.user);
  const { data: isAdmin = false } = useAdminStatusQuery({
    userId: accountId as number,
  });

  const dispatch = useAppDispatch();

  const {
    comparator: defaultComparator,
    metricType,
    value: defaultValue,
  } = defaultValues;

  const [group, setGroup] = useState<TriggerGroup>(
    getTriggerGroupByType(metricType) ?? TriggerGroup.DEVICE,
  );

  const { types } = useMemo(() => {
    return { types: getTriggerType(group) };
  }, [group]);

  const [selectedType, setSelectedType] = useState<TriggerType>(
    types.find((t) => metricType === t.value) ?? types[0],
  );
  const [rule, setRule] = useState<string>(
    defaultComparator ?? selectedType.defaultRule,
  );

  const [baseline, setBaseline] = useState<number>(
    (defaultValue as DeltaValue)?.lookback_window_size ?? 1800,
  );

  const [value, setValue] = useState<number>(
    selectedType.transformOnFetch?.(defaultValue as number) ??
      Number(defaultValue) ??
      selectedType.defaults?.[defaultComparator] ??
      0,
  );

  const [zones, setZones] = useState<number[]>(
    Array.isArray(defaultValue) ? defaultValue : [-1],
  );

  const handleBaselineChange = (e: SelectChangeEvent<unknown>) => {
    onChange?.();
    setBaseline(e.target.value as number);
  };

  const handleGroupChange = (e: SelectChangeEvent<unknown>) => {
    onChange?.();
    const newType = getTriggerType(e.target.value as TriggerGroup)[0];
    setGroup(e.target.value as TriggerGroup);
    setSelectedType(newType);
    setRule(newType.defaultRule);
    if (newType.defaultRule === 'safe_zone') {
      setZones([-1]);
      return;
    }
    setValue(
      (newType.defaults?.[newType.defaultRule] as number) ?? newType.min ?? 0,
    );
  };

  useEffect(() => {
    setRule(defaultComparator);
  }, [defaultComparator]);

  useEffect(() => {
    if (typeof editedId === 'number' && editedId === id) {
      dispatch(
        (isReminder ? updateReminderCondition : updateEditedRule)({
          metricType: selectedType.value,
          comparator: rule,
          baseline: rule.includes('delta') ? baseline : undefined,
          value:
            selectedType.value === 'safe_zone'
              ? zones.includes(-1)
                ? null
                : (zones as number[])
              : selectedType.transformValue?.(value) ?? value,
        }),
      );
    }
  }, [group, selectedType, rule, value, zones, baseline]);

  const handleTypeChange = (e: SelectChangeEvent<unknown>) => {
    const newType = types.find((type) => type.value === e.target.value)!;
    setSelectedType(newType);
    setRule(newType.defaultRule);
    onChange?.();
    setRule(newType.defaultRule);
    if (newType.defaultRule === 'safe_zone') {
      setZones([-1]);
      return;
    }
    setValue(
      (newType.defaults?.[newType.defaultRule] as number) ?? newType.min ?? 0,
    );
  };

  useImperativeHandle(ref, () => ({
    getFieldsData() {
      return {
        type: selectedType,
        group,
        rule,
        baseline,
        value: selectedType.transformValue?.(value) ?? value,
        zones:
          selectedType.value === 'safe_zone'
            ? zones.includes(-1)
              ? null
              : (zones as number[])
            : undefined,
      };
    },
    reset() {
      if (originalRule) {
        const group = getTriggerGroupByType(originalRule.metricType)!;
        setGroup(group);
        setSelectedType(
          getTriggerType(group).find(
            (type) => type.value === originalRule.metricType,
          )!,
        );
        setRule(originalRule.comparator);

        if (originalRule.metricType.includes('delta')) {
          setBaseline((originalRule.value as DeltaValue).lookback_window_size);
          setValue((originalRule.value as DeltaValue).delta);
        }

        if (typeof originalRule.value === 'number') {
          setValue(originalRule.value);
        }
        if (Array.isArray(originalRule.value)) {
          setZones(originalRule.value);
          return;
        }
      }
    },
  }));

  return {
    handleGroupChange,
    handleTypeChange,
    types,
    selectedType,
    selectedGroup: group,
    selectedRule: rule,
    groups,
    defaultComparator,
    defaultValue: selectedType.transformOnFetch?.(value) ?? value,
    setRule: (value: SetStateAction<string>) => {
      setRule(value);
      onChange?.();
    },
    setValue: (value: SetStateAction<number>) => {
      setValue(value);
      onChange?.();
    },
    setZones: (value: SetStateAction<number[]>) => {
      setZones(value);
      onChange?.();
    },
    value,
    zones,
    rule,
    isSafeZoneEmpty,
    isAdmin,
    baseline,
    handleBaselineChange,
  };
};
