import {
  Box,
  Divider,
  Switch,
  SwitchProps,
  Tooltip,
  alpha,
  styled,
} from '@mui/material';
import { CopyButton, EditButton, RemoveButton } from 'components/_extra';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { Title, Wrapper } from './styled';
import SmallScreenControls from './SmallScreenControls';
import { AlerterRecipientManagerSummary } from 'types/alerters';
import { Icon } from '@iconify/react';
import { errorFilled } from 'assets/iconify';
import { caregiverRole } from 'services/caregiver-role';
import ShowButton from 'components/_extra/Buttons/ShowButton';

export interface AlertCardProps {
  title: string;
  isActive?: boolean;
  children?: React.ReactNode;
  onSwitchToggle?: SwitchProps['onChange'];
  onCopy?: () => void;
  onRemove?: () => void;
  onEdit?: () => void;
  removeButtonLabel?: string;
  recipientManagers: AlerterRecipientManagerSummary[];
  childrenMaxWidth?: number;
  isReminder?: boolean;
}

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.palette.error.main};
  width: ${({ theme }) => theme.spacing(2.5)};
  height: ${({ theme }) => theme.spacing(2.5)};
`;

const AlertCard = ({
  title,
  isActive,
  children,
  onCopy,
  onEdit,
  onRemove,
  onSwitchToggle,
  childrenMaxWidth,
  removeButtonLabel = 'Delete',
  recipientManagers,
  isReminder,
}: AlertCardProps) => {
  const [isToggleActive, setIsToggleActive] = useState(isActive);
  const [disableToggle, setDisableToggle] = useState(false);

  const isDisabled = caregiverRole.value === 'viewer';

  const handlePropagation = (e: MouseEvent<HTMLElement>, func?: () => void) => {
    e.stopPropagation();
    e.bubbles = false;
    func?.();
  };

  const handleToggle = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    e.stopPropagation();
    onSwitchToggle?.(e, checked);
    setIsToggleActive(checked);
    setDisableToggle(true);
    setTimeout(() => {
      setDisableToggle(false);
    }, 2000);
  };

  useEffect(() => {
    if (!disableToggle) {
      setIsToggleActive(isActive);
    }
  }, [isActive]);

  return (
    <Wrapper isActive={false} onClick={onEdit} className="with-hover-effect">
      <Box flexGrow={1}>
        <Box display="flex" alignItems="start" gap={2} maxWidth="100%">
          <Box flexGrow={1} maxWidth="100%">
            <Box
              display="grid"
              gridTemplateColumns="auto 1fr"
              alignItems="center"
              gap={1}
              height="32px"
              overflow="hidden"
              onClick={(e) => handlePropagation(e)}
            >
              <Switch
                size="small"
                color="secondary"
                checked={isToggleActive}
                onChange={handleToggle}
                disabled={disableToggle || isDisabled}
              />
              <Box
                display="flex"
                alignItems="center"
                gap={0.5}
                onClick={onEdit}
              >
                <Title variant="subtitle2" noWrap>
                  {title}
                </Title>
                {recipientManagers.length === 0 && (
                  <Tooltip
                    placement="top-start"
                    title={
                      <>
                        Missing an active recipient set, <br /> this{' '}
                        {isReminder ? 'reminder' : 'alert'} will not take
                        effect.
                      </>
                    }
                  >
                    <StyledIcon icon={errorFilled.icons.normal} />
                  </Tooltip>
                )}
              </Box>
            </Box>
            <Divider
              sx={{
                mt: 1,
                mb: 2,
                borderColor: ({ palette }) => alpha(palette.primary.main, 0.23),
              }}
            />
          </Box>
          <Box display={{ xxs: 'none', sm: 'flex' }} gap={1}>
            {isDisabled ? (
              <ShowButton
                onClick={(e) => handlePropagation(e, onEdit)}
                toSee={`${isReminder ? 'reminder' : 'alert'} configuration`}
              />
            ) : (
              <EditButton onClick={(e) => handlePropagation(e, onEdit)} />
            )}
            <CopyButton
              disabled={!onCopy || isDisabled}
              onClick={(e) => handlePropagation(e, onCopy)}
            />
            <RemoveButton
              disabled={!onRemove || isDisabled}
              tooltipText={removeButtonLabel}
              onClick={(e) => handlePropagation(e, onRemove)}
            />
          </Box>
          <SmallScreenControls
            onCopy={onCopy}
            onEdit={onEdit}
            onRemove={onRemove}
            removeLabel={removeButtonLabel}
          />
        </Box>
        <Box
          display="flex"
          columnGap={3}
          rowGap={2}
          flexWrap="wrap"
          maxWidth={({ spacing }) =>
            childrenMaxWidth ? spacing(childrenMaxWidth) : '100%'
          }
        >
          {children}
        </Box>
      </Box>
    </Wrapper>
  );
};
export default AlertCard;
