import { Breakpoint, Grid2, Skeleton } from '@mui/material';

const AlertCategoryLoader = ({
  breakpoints,
}: {
  breakpoints: Partial<Record<Breakpoint, number>>;
}) => {
  return (
    <div>
      <Skeleton variant="text" width={72} sx={{ mb: 2 }} />
      <Grid2 container spacing={{ xxs: 2, sm: 3 }}>
        <Grid2 size={breakpoints}>
          <Skeleton variant="rounded" height={155} width="100%" />
        </Grid2>
        <Grid2 size={breakpoints}>
          <Skeleton variant="rounded" height={155} width="100%" />
        </Grid2>
        <Grid2 size={breakpoints}>
          <Skeleton variant="rounded" height={155} width="100%" />
        </Grid2>
      </Grid2>
    </div>
  );
};

export default AlertCategoryLoader;
