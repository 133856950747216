import {
  Comparator,
  activityTriggerTypes,
  deviceTriggerTypes,
  healthTriggerTypes,
  locationTriggerTypes,
} from './trigger-types';

export const TriggerGroup = {
  DEVICE: 'device',
  HEALTH: 'health',
  LOCATION: 'location',
  ACTIVITY: 'activity',
} as const;

export type TriggerGroup = (typeof TriggerGroup)[keyof typeof TriggerGroup];

export const getInputVariant = (options: Comparator[]) => {
  if (options.includes(Comparator.CHANGE)) {
    return 'select';
  }

  if (
    options.includes(Comparator.GREATER_THAN) ||
    options.includes(Comparator.GREATER_THAN)
  ) {
    return 'input';
  }

  return 'none';
};

export const getTriggerGroups = () => {
  return Object.values(TriggerGroup).map((value) => ({
    value,
    label: value.charAt(0).toUpperCase() + value.slice(1),
  }));
};

export const getTriggerGroupByType = (typeName: string) => {
  if (deviceTriggerTypes.find((type) => type.value === typeName)) {
    return TriggerGroup.DEVICE;
  }

  if (healthTriggerTypes.find((type) => type.value === typeName)) {
    return TriggerGroup.HEALTH;
  }

  if (locationTriggerTypes.find((type) => type.value === typeName)) {
    return TriggerGroup.LOCATION;
  }

  if (activityTriggerTypes.find((type) => type.value === typeName)) {
    return TriggerGroup.ACTIVITY;
  }
};

export const getTriggerType = (group: TriggerGroup) => {
  switch (group) {
    case TriggerGroup.DEVICE:
      return deviceTriggerTypes;
    case TriggerGroup.HEALTH:
      return healthTriggerTypes;
    case TriggerGroup.LOCATION:
      return locationTriggerTypes;
    case TriggerGroup.ACTIVITY:
      return activityTriggerTypes;
    default:
      return [];
  }
};

export const getRuleLabel = (rule: Comparator) => {
  switch (rule) {
    case Comparator.CHANGE:
      return 'On change';
    case Comparator.CONFIRMED_CHANGE:
      return 'Confirmed change';
    case Comparator.EQUAL:
      return 'Equal';
    case Comparator.NOT_EQUAL:
      return 'Not equal';
    case Comparator.EVENT:
      return 'Event';
    case Comparator.GREATER_THAN:
      return 'Greater Than';
    case Comparator.LESS_THAN:
      return 'Less Than';
    case Comparator.DELTA_GREATER_THAN:
      return 'Increased by';
    case Comparator.DELTA_LESS_THAN:
      return 'Decreased by';
    case Comparator.INSIDE:
      return 'Inside';
    case Comparator.OUTSIDE:
      return 'Outside';
  }
};

export * from './trigger-types';
