import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SettingsForm } from 'pages/ReminderDetails/hooks/use-settings';
import { toast } from 'react-toastify';
import { remindersApi } from 'store/api/reminders.api';
import {
  ChannelSet,
  MetricRule,
  ReminderSerializedSchedule,
  ScheduleRule,
} from 'types/alerters';
import {
  getRulesFromRawAlerterTrigger,
  transformChannelSet,
  transformRuleFromRaw,
} from 'utils/data-transformation';
import { MetricData } from '../alerter/alerter.slice';
import { alertersApi } from 'store/api/alerters.api';
import { getLocalDays } from 'utils/timezones';
interface ReminderState {
  id: number;
  settings: {
    id?: number;
    title: string | null;
    channels: ChannelSet[];
    channelSetId?: number;
    message: string | null;
    messageImageUrl: string | null;
  };
  trigger: {
    emptySafeZones?: boolean;
    rules: (MetricRule & { title?: string })[];
    editedRule?: {
      id: number;
      newRule: MetricData;
      originalRule: MetricData;
    };
  };
  isModifiedRecipientSet: boolean;
  areSettingsModified: boolean;
  triggerId: number | null;
  activeRecipientSets?: number[];
  scheduleRules: ScheduleRule[];
  wasScheduleSaved?: boolean;
  editedSchedule?: {
    id: number;
    newRule: ReminderSerializedSchedule;
    originalRule: ReminderSerializedSchedule;
  };
}

const initialState: ReminderState = {
  scheduleRules: [],
  triggerId: null,
  id: 0,
  settings: {
    title: null,
    channels: [],
    message: '',
    messageImageUrl: null,
  },
  trigger: {
    rules: [],
  },
  wasScheduleSaved: false,
  areSettingsModified: false,
  isModifiedRecipientSet: false,
};

export const reminderSlice = createSlice({
  name: 'reminder',
  initialState,
  reducers: {
    editReminderTrigger(state, action: PayloadAction<number>) {
      state.wasScheduleSaved = false;

      const ruleToEdit = state.scheduleRules.find(
        (rule) => rule.id === action.payload,
      );

      if (typeof ruleToEdit === 'undefined') {
        toast('An error occured. Please refresh the page and try again.', {
          type: 'error',
        });
        return;
      }

      state.editedSchedule = {
        ...ruleToEdit,
        id: action.payload,
        originalRule: {
          days: ruleToEdit.serializedSchedule.days,
          startTime: ruleToEdit.serializedSchedule.startTime,
        },
        newRule: {
          days: ruleToEdit.serializedSchedule.days,
          startTime: ruleToEdit.serializedSchedule.startTime,
        },
      };
    },
    updateReminderTrigger(
      state,
      action: PayloadAction<Partial<ReminderSerializedSchedule>>,
    ) {
      if (state.editedSchedule) {
        state.editedSchedule.newRule = {
          ...state.editedSchedule.newRule,
          ...action.payload,
        };
      }
    },
    cancelReminderTriggerEditing(state, action: PayloadAction<boolean>) {
      state.editedSchedule = undefined;

      state.wasScheduleSaved = action.payload;
    },
    editReminderCondition(state, action: PayloadAction<number>) {
      const ruleToEdit = state.trigger.rules.find(
        (rule) => rule.id === action.payload,
      );

      if (typeof ruleToEdit === 'undefined') {
        toast('An error occured. Please refresh the page and try again.', {
          type: 'error',
        });
        return;
      }

      state.trigger.editedRule = {
        ...ruleToEdit,
        id: action.payload,
        originalRule: {
          comparator: ruleToEdit.comparator,
          metricType: ruleToEdit.metricType,
          value: ruleToEdit.value,
        },
        newRule: {
          comparator: ruleToEdit.comparator,
          metricType: ruleToEdit.metricType,
          value: ruleToEdit.value,
        },
      };
    },
    updateReminderCondition(state, action: PayloadAction<MetricData>) {
      if (state.trigger.editedRule) {
        state.trigger.editedRule.newRule = action.payload;
        state.trigger.emptySafeZones = false;
      }
    },
    updateReminderActiveRecipientSets(state, action: PayloadAction<number[]>) {
      state.activeRecipientSets = action.payload;
    },
    cancelConditionEditing(state) {
      state.trigger.editedRule = undefined;
      state.trigger.emptySafeZones = false;
    },
    updateReminderSettingsState(state, action: PayloadAction<SettingsForm>) {
      const { channels, title, message } = action.payload;
      state.settings = {
        ...state.settings,
        channels,
        title,
        message,
      };
    },
    resetEditedCondition(state, action: PayloadAction<number>) {
      state.trigger.emptySafeZones = false;
      const editedRule = state.trigger.editedRule;
      const index = state.trigger.rules.findIndex(
        (rule) => rule.id === action.payload,
      );
      if (index !== -1 && editedRule) {
        state.trigger.rules[index] = {
          ...state.trigger.rules[index],
          ...editedRule.originalRule,
        };
        state.trigger.editedRule = undefined;
        return;
      }
      toast.error('An error occured.');
    },
    removeCondition(state, action: PayloadAction<number>) {
      state.trigger.rules = state.trigger.rules.filter(
        (rule) => rule.id !== action.payload,
      );
    },
    setConditionEmptyZoneWarning(state) {
      state.trigger.emptySafeZones = true;
    },
    setReminderSettingsAsModified(state) {
      state.areSettingsModified = true;
    },
    setReminderSettingsAsUntouched(state) {
      state.areSettingsModified = false;
    },
    updateMetricRuleList(state, action: PayloadAction<MetricRule[]>) {
      state.trigger.rules = action.payload;
    },
    resetState(state) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) =>
    builder
      .addMatcher(
        remindersApi.endpoints.getReminderInfo.matchFulfilled,
        (state, action) => {
          const result = action.payload.result;
          state.id = result.id;
          state.triggerId = result.alerter_trigger.id;
          state.settings = {
            id: result.id,
            channels: transformChannelSet(result.alerter_channel_set.channels),
            message: result.custom_message_template,
            title: result.title,
            channelSetId: result.alerter_channel_set.id,
            messageImageUrl: result.message_image_url,
          };
          state.activeRecipientSets = result.alerter_recipient_managers.map(
            (set) => set.id,
          );
        },
      )
      .addMatcher(
        remindersApi.endpoints.getReminderTrigger.matchFulfilled,
        (state, action) => {
          const { result } = action.payload;
          state.triggerId = result.id;
          state.scheduleRules = result.schedule_rules.map((rule) => ({
            id: rule.id,
            title: rule.title,
            enabled: rule.enabled,
            alerterTriggerId: rule.alerter_trigger_id,
            serializedSchedule: getLocalDays(
              rule.serialized_schedule.start_time,
              rule.serialized_schedule.days_of_week,
            ),
          }));
          state.trigger.rules = getRulesFromRawAlerterTrigger(result);
        },
      )
      .addMatcher(
        remindersApi.endpoints.addScheduleRuleToReminderTrigger.matchFulfilled,
        (state, action) => {
          const rules = action.payload.result.schedule_rules;
          if (rules.length > state.scheduleRules.length) {
            toast.success('The condition was successfully added.');
          }
          state.scheduleRules = rules.map(
            ({ alerter_trigger_id, serialized_schedule, ...rest }) => ({
              alerterTriggerId: alerter_trigger_id,
              serializedSchedule: getLocalDays(
                serialized_schedule.start_time,
                serialized_schedule.days_of_week,
              ),
              ...rest,
            }),
          );
        },
      )
      .addMatcher(
        alertersApi.endpoints.addRuleToAlerterTrigger.matchFulfilled,
        (state) => {
          state.trigger.emptySafeZones = false;
        },
      )
      .addMatcher(
        remindersApi.endpoints.removeRuleFromReminderTrigger.matchFulfilled,
        (state, action) => {
          const id = action.payload.result.id;
          state.scheduleRules = state.scheduleRules.filter(
            (trigger) => trigger.id !== id,
          );

          toast.success('The condition was succesfully removed.');
        },
      )
      .addMatcher(
        remindersApi.endpoints.updateRuleInReminderTrigger.matchFulfilled,
        (state, action) => {
          state.trigger.emptySafeZones = false;
          const result = action.payload.result;
          const index = state.scheduleRules.findIndex(
            (trigger) => trigger.id === result.id,
          );

          if (index === -1) {
            toast.warning('An error occured. Please, try again');
          }

          state.scheduleRules[index] = {
            ...state.scheduleRules[index],
            enabled: result.enabled,
            serializedSchedule: getLocalDays(
              result.serialized_schedule.start_time,
              result.serialized_schedule.days_of_week,
            ),
            title: result.title,
          };
        },
      )
      .addMatcher(
        alertersApi.endpoints.updateRuleInAlerterTrigger.matchFulfilled,
        (state, action) => {
          const index = state.trigger.rules.findIndex(
            (rule) => rule.id === action.payload.result.id,
          );
          state.trigger.rules[index] = transformRuleFromRaw(
            action.payload.result,
          );
        },
      ),
});

export const {
  updateReminderSettingsState,
  setReminderSettingsAsModified,
  setReminderSettingsAsUntouched,
  cancelConditionEditing,
  editReminderCondition,
  updateReminderCondition,
  resetEditedCondition,
  removeCondition,
  updateMetricRuleList,
  setConditionEmptyZoneWarning,
  editReminderTrigger,
  updateReminderTrigger,
  cancelReminderTriggerEditing,
  resetState,
  updateReminderActiveRecipientSets,
} = reminderSlice.actions;
export default reminderSlice.reducer;
