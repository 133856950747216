import { Button, Grid2, Typography } from '@mui/material';
import { usePollAllDevicesMutation } from 'store/api/admin.api';
import { toast } from 'react-toastify';

interface Props {
  profileId: number;
}

const PollDevices = ({ profileId }: Props) => {
  const [pollAllDevices, { isLoading }] = usePollAllDevicesMutation();

  const handleClick = () => {
    pollAllDevices({
      accountId: profileId,
    }).then((result) => {
      if ((result as Record<string, unknown>).error) {
        toast('There was an error. Please try again.', { type: 'error' });
        return;
      }

      toast('Devices polled succesfully!', { type: 'success' });
    });
  };

  return (
    <Grid2 container rowGap={1} alignItems="center">
      <Grid2 size={12}>
        <Typography variant="h6" my={1}>
          Poll Devices
        </Typography>
      </Grid2>
      <Grid2 size={6}>Poll All Devices</Grid2>
      <Grid2 size={6}>
        <Button onClick={handleClick} disabled={isLoading}>
          Poll
        </Button>
      </Grid2>
    </Grid2>
  );
};

export default PollDevices;
