import { Box, TextField, Typography } from '@mui/material';
import { HelpIcon } from 'components/HelpIcon';
import { FocusEventHandler, HTMLProps } from 'react';

const RepeatCount = ({
  defaultValue,
  inputProps = {},
  error,
  readOnly,
}: {
  defaultValue?: number;
  inputProps?: HTMLProps<HTMLInputElement>;
  error?: string;
  readOnly?: boolean;
}) => {
  const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    if (e.target.value.length === 0) {
      e.target.value = '1';
    }
    if (parseInt(e.target.value) > 5) {
      e.target.value = '5';
      return;
    }
    if (parseInt(e.target.value) < 1) {
      e.target.value = '1';
      return;
    }
  };

  return (
    <Box pb={2}>
      <Typography color="primary" pb={1.5} whiteSpace="nowrap">
        Attempts
      </Typography>
      <Box display="flex" gap={1} alignItems="center">
        <TextField
          size="medium"
          defaultValue={defaultValue ?? 10}
          type="number"
          disabled={readOnly}
          slotProps={{
            htmlInput: {
              min: 1,
              max: 5,
              ...inputProps,
            },
          }}
          onBlur={handleBlur}
          sx={({ spacing }) => ({
            maxHeight: spacing(5),
            maxWidth: spacing(8),
            input: {
              padding: 0,
            },
          })}
          // helperText={error}
          error={!!error}
        />
        <HelpIcon
          tooltipPlacement="top-end"
          tooltipSx={{ pb: 1 }}
          message={
            <>
              Number of attempts <br /> per hierarchy level.
            </>
          }
        />
      </Box>
    </Box>
  );
};

export default RepeatCount;
