import { Icon } from '@iconify/react';
import { Box, Button, Popover } from '@mui/material';
import { close, draw } from 'assets/iconify';
import { useContext, useState } from 'react';
import ShapeButton from './ShapeButton';
import { NewSafeZoneContext } from 'pages/PatientAccount/components/SafeZones/AddSafeZoneModal';
import { RegionsType } from 'types/safe-zones';

interface Props {
  selectedShape: RegionsType;
  setSelectedShape: (type: RegionsType) => void;
  handleClearDrawing?: () => void;
  showClear?: boolean;
}

const DrawButton = ({
  selectedShape,
  setSelectedShape,
  handleClearDrawing,
  showClear,
}: Props) => {
  const { setType: setContextType } = useContext(NewSafeZoneContext);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleModalClose = () => setAnchorEl(null);

  const handleShapeChange = (type: RegionsType) => {
    setSelectedShape(type);
    setContextType(type);
    handleModalClose();
  };

  const handleCloseEditing = () => {
    handleClearDrawing?.();
  };

  return (
    <>
      {showClear ? (
        <Button
          onClick={handleCloseEditing}
          sx={{ mr: 2, width: ({ spacing }) => spacing(13.5) }}
          color="red"
          startIcon={<Icon icon={close.icons.normal} />}
        >
          Clear
        </Button>
      ) : (
        <Button
          onClick={handleOpen}
          sx={{ mr: 2, width: ({ spacing }) => spacing(13.5) }}
          startIcon={<Icon icon={draw.icons.normal} />}
        >
          Shape
        </Button>
      )}
      <Popover
        elevation={4}
        anchorPosition={{
          top: -20,
          left: 0,
        }}
        onClose={handleModalClose}
        anchorOrigin={{
          vertical: -16,
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={!!anchorEl}
        anchorEl={anchorEl}
        slotProps={{
          paper: { sx: { p: 2 } },
        }}
      >
        <Box display="flex" gap={2}>
          <ShapeButton
            isSelected={selectedShape === RegionsType.CIRCLE_TYPE}
            onClick={() => handleShapeChange(RegionsType.CIRCLE_TYPE)}
            type={RegionsType.CIRCLE_TYPE}
          />
          <ShapeButton
            isSelected={selectedShape === RegionsType.POLYGON_TYPE}
            onClick={() => handleShapeChange(RegionsType.POLYGON_TYPE)}
            type={RegionsType.POLYGON_TYPE}
          />
          <ShapeButton
            isSelected={selectedShape === RegionsType.PATH_TYPE}
            onClick={() => handleShapeChange(RegionsType.PATH_TYPE)}
            type={RegionsType.PATH_TYPE}
          />
        </Box>
      </Popover>
    </>
  );
};

export default DrawButton;
