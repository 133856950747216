import {
  Autocomplete,
  TextField,
  TextFieldProps,
  alpha,
  inputBaseClasses,
  styled,
} from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import {
  TimeOption,
  timePickerOptions as options,
} from 'utils/get-timpicker-options';

export const StyledTextField = styled(TextField)(({ theme }) => ({
  minWidth: theme.spacing(15),
  fontSize: 16,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  [`.${inputBaseClasses.root}`]: {
    maxHeight: theme.spacing(5),
    padding: theme.spacing(0),
    [`.${inputBaseClasses.input}`]: {
      padding: theme.spacing(1, 0, 1, 1.5),
      height: theme.spacing(3),
    },
  },
  [`.${inputBaseClasses.readOnly}`]: {
    color: alpha(theme.palette.primary.main, 0.6),
  },
}));

interface Props extends Omit<TextFieldProps, 'value' | 'onChange'> {
  value?: number;
  readOnly?: boolean;
  onChange?: (seconds: number) => void;
}

const TimePicker = ({ readOnly, value, onChange, ...rest }: Props) => {
  const [time, setTime] = useState<TimeOption | null>(
    options.find((o) => o.value === value) ?? {
      value: 86400,
      label: '12:00am',
    },
  );
  const handleChange = (_: SyntheticEvent, option: TimeOption) => {
    onChange?.(option.value);
    setTime(option);
  };

  useEffect(() => {
    setTime(
      options.find((o) => o.value === value) ?? {
        value: 86400,
        label: '12:00am',
      },
    );
  }, [value]);

  return (
    <Autocomplete
      disableClearable
      openOnFocus
      slotProps={{
        listbox: { sx: { maxHeight: ({ spacing }) => spacing(20) } },
      }}
      options={options}
      getOptionLabel={(option) => option.label}
      onChange={handleChange}
      value={time ?? undefined}
      readOnly={readOnly}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderInput={(props) => (
        <StyledTextField
          multiline={false}
          {...props}
          {...rest}
          slotProps={{
            input: {
              ...props.InputProps,
              readOnly,
              slotProps: {
                input: props.inputProps,
              },
            },
          }}
        />
      )}
    />
  );
};

export default TimePicker;
